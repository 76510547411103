import React from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import UserIcon from "./UserIcon";
import { useSelector, useDispatch } from 'react-redux';
import { clearUser } from '../services/redux/userSlice';
import { logout } from '../services/api/authentication.js';
import Button from "../store/components/Button";
import mixpanel from 'mixpanel-browser';

const MobileHeader = ({text}) => {

    const navigate = useNavigate();
    const [mobileMenuIsOpen, setMobileMenuIsOpen] = React.useState(false);
    const user = useSelector(state => state.user.value);
    const dispatch = useDispatch();

    const toggleMobileMenu = () => {
        setMobileMenuIsOpen(!mobileMenuIsOpen);
    }

    const handleLogout = () => {
        logout();
        localStorage.removeItem('isHijacked');
        dispatch(clearUser());
        // navigate('/');
        mixpanel.track('Logged Out');
        mixpanel.reset();
    };


    const pages = {
        'FAQs': "/help",
        'Profile': "/profile",
        // 'Camera': "/",
        // 'For Teams': "/teams",
        // 'For MedTechs': "/medtechs",
    }

    return (
        <>
        <StyledMobileHeader>
            <Logo>
                <img src="/images/logo_nobkg.png" alt="Orchid Surgical" />
            </Logo>
            <Logo>
                <span>{text}</span>
            </Logo>
            <MobileMenu>
                <div onClick={toggleMobileMenu}>
                <UserIcon 
                    user={user} 
                    size={32} 
                />
                </div>
            </MobileMenu>
            <Collapse in={mobileMenuIsOpen}>
                <MobileMenuDropdown isOpen={mobileMenuIsOpen}>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', gap:'10px'}}>
                        {Object.keys(pages).map((page, index) => {
                            return <HeaderLink key={index} to={pages[page]} onClick={() => setMobileMenuIsOpen(false)}>{page}</HeaderLink>
                        }
                        )}
                        </div>
                        <div style={{position: 'relative', alignSelf: 'center', justifySelf:'center'}}>
                        <Button text="Sign Out" color="#444A9F" textColor="white" width="120px" onClick={handleLogout}/>
                        </div>
                </MobileMenuDropdown>
            </Collapse>
        </StyledMobileHeader>
        </>
    );
}

export default MobileHeader;

const StyledMobileHeader = styled.header`
    display: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    width: 100%;
    padding: 10px 36px;

    @media (max-width: 900px) {
        display: flex;
        padding: 10px 20px;
    }
`;


const Logo = styled.div`
    display: inline-flex;
    align-items: center;
    height: 70px;

    @media (max-width: 900px) {
        height: 40px;
    }

    img {
        height: inherit;
        margin-top: 10px;

        @media (max-width: 900px) {
            height: 55px;
            margin-top: 3px;
        }
    }

    span {
        font-size: 20px;
        font-weight: 500;
        margin-left: 15px;
        letter-spacing: 6px;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        white-space: nowrap;
        cursor: default;

        @media (max-width: 900px) {
            font-size: 20px;
            font-weight: 500;
            letter-spacing: .8vw;
            margin: 0;
        }
    }
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
`;

const HeaderLink = styled(Link)`
    position: relative;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    color: white !important;
    transition: all 0.2s ease;
    text-align: left;

    &:hover {
        color: lightslategray !important;
        transform: scale(1.05);
    }

    @media (max-width: 900px) {
        color: black !important;
        font-size: 20px;
    }
`;

const MobileMenu = styled.div`
    position: relative;
    display: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    @media (max-width: 900px) {
        display: flex;
    }
`;

const MobileMenuButton = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background-color: ${props => props.isOpen ? "lightgray" : "transparent"};
    padding: 10px 10px 5px 10px;
    border-radius: 10px;

    span {
        width: 25px;
        height: 3px;
        background-color: black;
        margin-bottom: 4px;
        border-radius: 5px;
    }

`;

const MobileMenuDropdown = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 90px;
    left: 2.5%;
    width: 95%;
    height: 50vh;
    opacity: ${props => props.isOpen ? "1" : "0"};
    transition: all 0.5s ease;

    padding: 10px 20px;
    background-color: white;
    border-radius: 10px;
    z-index: 100;
    gap: 20px;
    text-align: left;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);

    @media (max-width: 460px) {
        height: 72vh;
    }
`;