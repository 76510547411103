import React from "react";
import { useParams } from "react-router-dom";
import { styled, keyframes, css } from "styled-components";
import { useState, useEffect, useRef } from "react";
import { updateCase } from "../services/api/cases.js";
import { getAttachment, uploadThumbnail, generateManifestLink, createAttachment, updateAttachment } from "../services/api/attachments.js";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { dateFromTimestamp } from "../services/util/metadata.js";
import EditorTimeline from "./EditorTimeline.js";
import Thumbnail from "./Thumbnail.js";
import { OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import { Link, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { hmsFromSeconds, hmsTextFromSeconds, reduceStringWords } from "../services/util/metadata.js";
import { getCurrentVideoFrame } from "../services/util/video.js";
import VideoPlayer from "./VideoPlayer.js";
import videojs from "video.js";
import * as Sentry from "@sentry/react";
import mixpanel from 'mixpanel-browser';
import useWindowSize from "../hooks/useWindowSize.js";
import { useDispatch, useSelector } from 'react-redux';
import SymbolBubble from './SymbolBubble.js';
import TimeAgo from "./TimeAgo.js";
import {
  resetState,
  addCut,
  setCurrent,
  setVersions,
  incrementCurrent,
  decrementCurrent,
  setClipActive
} from '../services/redux/timelineSlice.js'
import { 
  parseMediaManifest,
  cutSegments,
  generateMediaManifest,
  prependBaseURI,
  parseThumbnailManifest,
  parseMasterManifest,
  generateMasterManifest,
  } from "../services/util/hls.js";
import { max } from "moment";

const TimelineElement = ({
  attachment, 
  editorRef, 
  playerRef, 
  playerReady,
  clips, 
  thumbnails,
  totalActiveTime,
  setDisplayTime,
  selectedClip, 
  setSelectedClip,
  selectedClipRef,
  zoom,
  setZoom,
  clipsOffset,
  clipsZoom,
  clipsWidth,
  maxZoom,
  timelineWindowRef,
  }) => {

  const [scrollPosition, setScrollPosition] = useState(0);
  const [seekTime, setSeekTime] = useState(0);
  // const timelineWindowRef = useRef(null);
  const timelineRef = useRef(null);

  const [cursorAnimationState, setCursorAnimationState] = useState('paused');

  const [timeRemainder, setTimeRemainder] = useState(0);
  const timeRef = useRef(null);

  useEffect(() => {
      if (playerReady) {
        console.log('player ready, setting animation states')
        playerRef.current.on('pause', handlePause);
        playerRef.current.on('waiting', handlePause);
        playerRef.current.on('ended', handlePause);
        playerRef.current.on('playing', handlePlay);
        playerRef.current.on('seeked', handleSeeked);
    }
    return () => {
      playerRef.current.off('pause', handlePause);
      playerRef.current.off('waiting', handlePause);
      playerRef.current.off('ended', handlePause);
      playerRef.current.off('playing', handlePlay);
      playerRef.current.off('seeked', handleSeeked);
      console.log('player disposed, removing animation states')
    }
  }, [playerReady]);

  function setTimerActive(timerActive) {
    if (timerActive) {
      //wait for duration of timeRemainder, then set the time to the next second
      setTimeout(() => {
        setDisplayTime((displayTime) => displayTime + 1);
        timeRef.current = setInterval(() => {
          setDisplayTime((displayTime) => displayTime + 1);
        }, 1000);
      }, timeRemainder * 1000);
    } else {
      clearInterval(timeRef.current);
    }
  }

  const handleSetTime = (time) => {
    let wasPaused = playerRef.current.paused();
    playerRef.current.pause();
    playerRef.current.currentTime(time);
    setDisplayTime(Math.floor(time));
    setTimeRemainder(1-(time % 1));
    if (!wasPaused)
      playerRef.current.play();
  }

  const handlePlay = (e) => {
    const time = playerRef.current.currentTime();
    setCursorAnimationState('running');
    setTimeRemainder(1-Number((time % 1).toFixed(2)));
    setDisplayTime(Math.floor(time));
    setTimerActive(true);
    console.log('cursor animation running')
  }

  const handlePause = (e) => {
    setTimerActive(false);
    setCursorAnimationState('paused');
  }

  const handleSeeked = (e) => {
    const time = playerRef.current.currentTime();
    setSeekTime(time + Math.random() * 0.0001); //hack to force the cursor to update
    setDisplayTime(Math.floor(time));
    setTimeRemainder(1 - Number((time % 1).toFixed(2)));    
  }

  function provideScrollLock() {
    //stop document scroll
    document.body.style.overflow = 'hidden';
  }

  function releaseScrollLock() {
    //restore document scroll
    document.body.style.overflow = 'auto';
  }

  const handleZoom = (e) => {
    e.stopPropagation();

    const isZoom = (Math.abs(e.deltaX) < Math.abs(e.deltaY))

    if (!isZoom || (zoom===1 && e.deltaY > 0) || (zoom===maxZoom && e.deltaY < 0)){
      clipsOffset.current = timelineWindowRef.current.scrollLeft;
      return;
    }

    const zoomRate = 0.01*zoom;
    const oldZoom = clipsZoom.current;
    const zoomAmount = zoomRate * Math.max(-50, Math.min(50, e.deltaY));
    const newWidth = (editorRef.current.clientWidth - 32) * (oldZoom - zoomAmount);

    //set the new scroll position so that the timeline expands and contracts around the mouse position
    const mousePosition = e.clientX - timelineWindowRef.current.getBoundingClientRect().left;
    // const mousePositionInVideo = (mousePosition + timelineWindowRef.current.scrollLeft);
    const mousePositionInVideo = (mousePosition + clipsOffset.current);
    const mousePercentInVideo = mousePositionInVideo / (oldZoom * (editorRef.current.clientWidth - 32));
    clipsOffset.current = mousePercentInVideo * newWidth - mousePosition;
    timelineWindowRef.current.scrollLeft = mousePercentInVideo * newWidth - mousePosition;
    clipsZoom.current = Math.max(1, Math.min(maxZoom, oldZoom - zoomAmount));
    setZoom(zoom => Math.max(1, Math.min(maxZoom, oldZoom - zoomAmount)));
  }

  function renderTimelineThumbnails(startTime, duration, width) {
    //Return an array of imgs with the thumbnails. The thumbnails should fill the width,
    //and the displayed thumbnail should be the one that is closest to the relevant time
    let clipThumbnails = [];
    // let thumbnailDuration = thumbnails[1].startTime - thumbnails[0].startTime;
    // let thumbnailDuration = thumbnails[0].duration;
    let thumbnailDuration = 90;
    let tileDuration = thumbnailDuration / 9;

    //calculate the number of thumbnails that will fit in the width
    let thumbnailCount = Math.ceil(width / 106);

    //calculate the timestamp within the clip corresponding to the left edge of each thumbnail
    let times = [];
    for (let i = 0; i < thumbnailCount; i++) {
      times.push(Math.round(i * duration * 106 / width) + startTime);
    }

    //each thumbnail image is divided into a 3x3 grid. The thumbnail corresponding to the start time is the 0th image.
    //The thumbnail for n seconds later is the 1st image, 2*n seconds later is the 2nd image, etc.
    //find the thumbnail that is closest to the time, and display it

    times.forEach((time, index) => {
      let timeRounded = Math.round(time/tileDuration);
      let thumbnailIndex = Math.floor(timeRounded*tileDuration/thumbnailDuration);
      let tileIndex = timeRounded % 9;
      clipThumbnails.push(
        <Thumbnail
          key = { index }
          src = { thumbnails[thumbnailIndex]?.uri }
          n = { 3 }
          m = { 3 }
          k = { tileIndex }
          width = { 106 }
          height = { 60 }
        />
      )
    })

    return clipThumbnails;

  }

  function renderTimelineMarkers(z) {
    //Return an array of divs with the markers. The div size should be a function of the zoom, 
    //always a whole number of seconds, but never smaller than 20px.
    //The divs should be spaced evenly across the timeline
    let markers = [];
    
    //calculate the width of 1s markers
    const oneSecondWidth = z * (editorRef.current.clientWidth - 32) / totalActiveTime;

    //We want the markers to have several states, depending on the zoom level. Markers should scale, and then
    //and then change spacing as the zoom level changes.

    const markerSpacings = [1, 2, 5, 10, 20, 30, 60, 120, 300, 600, 1200, 1800, 3600];

    const markerWidths = markerSpacings.map(spacing => spacing * oneSecondWidth);

    //find the smallest marker width that is greater than 25px
    let markerWidth = markerWidths.find(width => width > 25);

    //markerCount should be the number of markers necessary to fill the timeline
    let markerCount = Math.ceil((z*(editorRef.current.clientWidth - 32) - 32) / markerWidth);

    for (let i = 0; i < markerCount; i++) {
      markers.push(
        <div
          key = { i }
          style = {{position: 'relative', width: markerWidth, height: '60px', borderLeft: '0px dashed rgba(200,200,200,.0)'}}
        >
          <div
            style = {{position: 'absolute', top: -24, left: 0, color: 'white', fontSize: '12px', padding: '2px', transform: 'translateX(-50%)', whiteSpace: 'nowrap', fontWeight: 'bold'}}
          >
            {(i ===0) && <span>&nbsp;&nbsp;&nbsp;0s</span>}
            {(i % 6 === 0) ? hmsTextFromSeconds(i * markerWidth / oneSecondWidth) : 
            '・'}
          </div>
        </div>
      )
    }
    return markers;
  }

  function renderTimelineScroll(z){
    //Returns a div whose width corresponds to the visible percent of the timeline
    //The div should be draggable, and should update the scroll position of the timelineWindow

    //calculate the visible percent of the timeline
    let visiblePercent = timelineWindowRef.current.clientWidth / (z*(editorRef.current.clientWidth - 32)) * 100;

    //calculate the position of the scroll handle
    let scrollHandlePosition = timelineWindowRef.current.scrollLeft / (z*(editorRef.current.clientWidth - 32)) * 100;

    return (
      <ScrollElement
        $scrollHandlePosition = {scrollHandlePosition}
        $visiblePercent = {visiblePercent}
        onClick = { (e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onMouseDown = { (e) => {
          e.stopPropagation();
          let originalX = e.clientX;
          let originalScroll = timelineWindowRef.current.scrollLeft;
          document.onmouseup = () => {
            document.onmouseup = null;
            document.onmousemove = null;
          }
          document.onmousemove = (e) => {
            let newScroll = originalScroll - (originalX - e.clientX) * z;
            clipsOffset.current = newScroll;
            timelineWindowRef.current.scrollLeft = newScroll;
          }
        }}
      />
    )
  }

  return(
  <>
  <TimelineWindow
    width = { editorRef.current.clientWidth - 30 }
    ref = { timelineWindowRef }
    onMouseOver = { provideScrollLock }
    onMouseOut = { releaseScrollLock }
    onWheel = { handleZoom }
    onScroll = { (e) => {
      e.preventDefault();
      e.stopPropagation();
      setScrollPosition(timelineWindowRef.current.scrollLeft);
    }}
  >
  <TimelineClipContainer 
    width = { zoom * (editorRef.current.clientWidth - 32) }
    ref = { timelineRef }
  >
    {clips.map((clip, index) => {
      let clipDuration = (((index<clips.length-1) ?  clips[index+1].startTime : attachment.user_metadata.duration ) - clip.startTime);
      let elementWidth = zoom * ((editorRef.current.clientWidth - 32) * clipDuration / totalActiveTime);
      return (
        <TimelineClipElement 
          key = { index }
          $active = { clip?.active }
          width = { Math.max(0, elementWidth - 4) }
          selected = { index === selectedClip }
          onClick = { (e) => {
            //if the clip is already selected, set time to to the click position
            //otherwise, select the clip
            //use the position within the timelineClipContainer to calculate the new time
            if (index === selectedClip) {
              let newTime = (e.clientX - timelineRef.current.getBoundingClientRect().left) / (timelineRef.current.clientWidth ) * totalActiveTime;
              setSeekTime(newTime);
              handleSetTime(newTime);
            } else
              setSelectedClip(index);
              selectedClipRef.current = index;
          }}
        >
          {thumbnails &&
          renderTimelineThumbnails(clip.startTime, clipDuration, elementWidth)
          }\
        </TimelineClipElement>
      )
    })}
    <TimelineMarkerOverlay>
      {timelineRef && renderTimelineMarkers(zoom)}
    </TimelineMarkerOverlay>
    <TimeCursor 
      $animationState={cursorAnimationState}
      $totalTime={totalActiveTime}
      $location={seekTime}
      onMouseDown={ (e) => {
        const wasPaused = playerRef.current.paused();
        playerRef.current.pause();
        e.stopPropagation();
        e.preventDefault();
        let originalX = e.clientX;
        let originalTime = playerRef.current.currentTime();
        document.onmouseup = () => {
          document.onmouseup = null;
          document.onmousemove = null;
          if (!wasPaused)
            playerRef.current.play();
        }
        document.onmousemove = (e) => {
          let newTime = (e.clientX - originalX ) / (editorRef.current.clientWidth - 32) * totalActiveTime / zoom + originalTime;
          newTime= Math.max(0, Math.min(attachment.user_metadata.duration, newTime));
          setSeekTime(newTime);
          handleSetTime(newTime);
        }
      }}
    >
      <img 
        src='/images/icons/player/time_cursor.svg'  
        style={{width: '15px', height: '15px', zIndex: 2, transform: 'translate(-6.5px, -16px)'}}
      />
    </TimeCursor>
  </TimelineClipContainer>
  </TimelineWindow>
  <div
      style={{
        position: 'relative',
        width: 'calc(100% - 32px)',
        height: '6px',
        marginTop: '-14px',
        pointerEvents: 'none',
        backgroundColor: 'rgba(100,100,100,1)',
        borderRadius: '3px',
        pointerEvents: 'auto',
        cursor: 'pointer',
      }}
      onClick = { (e) => {
        let newScrollPosition = (e.clientX - timelineWindowRef.current.getBoundingClientRect().left) / timelineWindowRef.current.clientWidth * timelineRef.current.clientWidth;
        timelineWindowRef.current.scrollLeft = newScrollPosition - timelineWindowRef.current.clientWidth / 2;
        clipsOffset.current = newScrollPosition - timelineWindowRef.current.clientWidth / 2;
      }}
    >
      {timelineWindowRef.current &&
        renderTimelineScroll(zoom)
      }
    </div>
  </>
  )
}

const ScrollElement = styled.div.attrs(({ $scrollHandlePosition, $visiblePercent }) => ({
  style: {
    left: `${$scrollHandlePosition}%`,
    width: `${$visiblePercent}%`,
    }
  }))`
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    cursor: grab;
    z-index: 3;
    background-color: lightgrey;
    border: 1px solid rgba(200,200,200,.5);
    border-radius: 5px;
    pointer-events: auto;
    transform-box: content-box;

    &:active {
      cursor: grabbing;
    }
`;

const moveTimeCursor = (x) => {
  return keyframes`
  from {
    left: ${x}%;
  }
  to {
    left: calc(100%) /*hack to get css to recognize the end of the animation*/
  }
`;
};

const TimeCursor = styled.div.attrs((props) => ({
  style: {
    left: `${props.$location/props.$totalTime*100}%`,
  },
}))`
  position: absolute;
  top: -6px;
  height: 70px;
  width: 2px;
  cursor: grab;
  background-color: white;
  border-radius: 1px; 
  animation: ${props => css`${moveTimeCursor(props.$location / props.$totalTime*100)} ${props.$totalTime - props.$location}s linear forwards`};
  animation-play-state: ${props => props.$animationState};

   &:active {
    cursor: grabbing;
  }
`

const TimelineMarkerOverlay = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 0px;
  z-index: 1;
  pointer-events: none;
`;

const TimelineWindow = styled.div.attrs( (props) => ({
  style: {
    width: `${props.width}px`,
  }
  }))`
  position: relative;
  display: flex;
  flex: 0 0 auto;
  height: 100px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0px;
  /* margin-top: 10px; */
  overflow-x: scroll;
  overflow-y: scroll;
  overscroll-behavior-x: contain;
  scrollbar-width: none; /*Firefox*/
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;


const TimelineClipContainer = styled.div.attrs( (props) => ({
    style: {
      width: `${props.width}px`,
    }
    }))`
    position: relative;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
    padding-left: 1px;
    cursor: pointer;
`;

const TimelineClipElement = styled.div.attrs( (props) => ({
    style: {
      width: `${props.width}px`,
      border: props.selected ? '2px solid #007bff' : '1px solid white',
    }
    }))`
    flex-direction: row;
    padding: 0;
    margin: 0;
    position: relative;
    box-sizing: content-box;
    display: ${props => props.$active ? 'flex' : 'none'};
    flex: 0 0 auto;
    gap: 0px;
    height: 60px;
    border-radius: 7px;
    border: 1px solid white;
    overflow: hidden;
    user-select: none;

    img {
      pointer-events: none;
    }

    &:hover {
      div {
        filter: brightness(1.2);
      }
    }
`;


const Editor = (props) => {

    const { attachment_id } = useParams();

    const navigate = useNavigate();

    const [attachment, setAttachment] = useState(null);
    const [downloadLink, setDownloadLink] = useState(null);

    const uploadIsOpen = useSelector(state => state.ui.uploadIsOpen);

    const playerRef = useRef(null);

    const [caseInfo, setCaseInfo] = useState(null);
    const [expandDescription, setexpandDescription] = useState(false);
    const [thumbnails, setThumbnails] = useState([]);
    const editorRef = useRef(null);

    const [displayTime, setDisplayTime] = useState(0);
    const [playPauseState, setPlayPauseState] = useState('paused');
    const [totalActiveTime, setTotalActiveTime] = useState(0);
    const [zoom, setZoom] = useState(1);
    const timelineWindowRef = useRef(null);
    const clipsOffset = useRef(0);
    const clipsWidth = useRef(0);
    const clipsZoom = useRef(1);
    const [maxZoom, setMaxZoom] = useState(10);
  

    const dispatch = useDispatch();
    const versions = useSelector(state => state.timeline.versions)
    const currentVersion = useSelector(state => state.timeline.current);
    const previousVersion = useSelector(state => state.timeline.previous);
    const numberOfVersions = useSelector(state => state.timeline.versions.length);
    const clips = useSelector(state => state.timeline.versions[currentVersion].clips);

    const [audioMuted, setAudioMuted] = useState(false);
    const [subtitle, setSubtitle] = useState('');
    const [draftSavedAt, setDraftSavedAt] = useState(null);

    useEffect(() => {
      if (attachment) {
        updateAttachment(attachment_id, {
          "draft_edits": {
            "versions": versions,
            "current_version": currentVersion,
            "audio_muted": audioMuted,
          }
        }).then((data) => {
          setDraftSavedAt(new Date());
          console.log("updated attachment with draft edits")
        }).catch((error) => {
          console.log("error updating attachment with draft edits", error)
        });
      }
    }, [currentVersion, audioMuted]);

    const beforeUnloadHandler = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return 'You have an upload in progress. Are you sure you want to leave?';
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);

    const [videoHeader, setVideoHeader] = useState(null);
    const [audioHeader, setAudioHeader] = useState(null);
    const [videoSegments, setVideoSegments] = useState(null);
    const [audioSegments, setAudioSegments] = useState(null);
    const [masterStreams, setMasterStreams] = useState(null);
    const [videoHasSeperateAudio, setVideoHasSeperateAudio] = useState(false);

    const [selectedClip, setSelectedClip] = useState(0);
    const selectedClipRef = useRef(selectedClip);

    const [numberOfSegments, setNumberOfSegments] = useState(1);
    
    function initLocalMasterManifest(downloadLink) {
      let streams
      return fetch(downloadLink, { method: 'GET', credentials: 'include' })
      .then(response => response.text())
      .then(data => parseMasterManifest(data))
      .then((s) => {
        streams = s;
        const baseURI = downloadLink.substring(0, downloadLink.lastIndexOf('/')+1);
        streams.forEach((stream) => {stream.uri = baseURI + stream.uri})
        let promises = streams.map((stream) => {
          return fetch(stream.uri, { method: 'GET', credentials: 'include' })
          .then(response => response.text())
          .then(data => {
            data = prependBaseURI(data, baseURI);
            return data;
          })
        })
        return Promise.all(promises)
      })
      .then((manifests) => {
        // parse the video and audio manifests and store the headers, segments, and footers
        // parse the image manifest and store the thumbnails
        // set the stream URIs to the local copies of video and audio
        // then, return the master manifest

        streams.forEach((stream, index) => {
          if (stream.type === 'video') {
            let {segments: s, header: h} = parseMediaManifest(manifests[index]);
            setVideoHeader(h);
            setVideoSegments(s);
          } else if (stream.type === 'audio') {
            let {segments: s, header: h} = parseMediaManifest(manifests[index]);
            setAudioHeader(h);
            setAudioSegments(s);
          } else if (stream.type === 'image') {
            let l = parseThumbnailManifest(manifests[index]);
            let promises = l.map((thumbnail) => {
              return fetch(thumbnail.uri, { method: 'GET', credentials: 'include' })
              .then(response => response.blob())
              .then(blob => URL.createObjectURL(blob))
            })
            Promise.all(promises)
            .then((urls) => {
              l.forEach((thumbnail, index) => {
                thumbnail.uri = urls[index];
              })
              setThumbnails(l);
            })
          }
        })
        setVideoHasSeperateAudio(streams.some(stream => stream.type === 'audio'));
        setMasterStreams(streams);
      })
    }
    
    useEffect(() => {
      //page title
      document.title = "Edit | Orchid Cloud";

      //we want to get the attachment_edit.m3u8 manifest
      //then, we want to extract the streams

      // get the manifest
      generateManifestLink(attachment_id)
      .then(data => data['manifest_link'])
      .then(manifest_link => {
        setDownloadLink(manifest_link.replace('attachment.m3u8', 'attachment_edit.m3u8'));
        initLocalMasterManifest(manifest_link.replace('attachment.m3u8', 'attachment_edit.m3u8'));
      })

      //reset state.timeline
      dispatch(resetState());

      // get the attatchment details
      getAttachment(attachment_id)
      .then((item) => {
          setAttachment(() => item);
          if (item.draft_edits.versions) {
            dispatch(setVersions(item.draft_edits.versions));
            dispatch(setCurrent({versionNumber: item.draft_edits.current_version}));
          }
          if (item.draft_edits.audio_muted !== undefined) {
            setAudioMuted(item.draft_edits.audio_muted);
          }

          const caseTitle = item?.subtitle ? item.subtitle : item.case.case_name;
          setCaseInfo({id: item.case.id, case_name: caseTitle, description: item.case.description, owner: item.case.owner, created_on: item.created_on});
          document.title = "Edit: " + caseTitle + " | Orchid Cloud";
          mixpanel.track('Started Editing Session', 
            {
              'Source': 'Editor Page', 
              'Attachment ID': attachment_id,
              'Attachment Owner': item.case.owner.id
            }
          );

          //total active time
          // setTotalActiveTime(Number(item.user_metadata.duration));
          let activeTime = 0;
          if (item.draft_edits.versions) {
            item.draft_edits.versions[item.draft_edits.current_version].clips.forEach((clip, index) => {
              if (clip?.active===true)
                activeTime += (((index<item.draft_edits.versions[item.draft_edits.current_version].clips.length-1) ?  item.draft_edits.versions[item.draft_edits.current_version].clips[index+1].startTime : item.user_metadata.duration) - clip.startTime);
            })
            setTotalActiveTime(activeTime);
          } else {
            setTotalActiveTime(item.user_metadata.duration);
          }
      })

      // setKeypressListeners();

      return () => {
        // removeKeypressListeners();
      }
    }, [attachment_id]);

    useEffect(() => {   

        removeKeypressListeners();

        //accumulate length of active segments
        if (attachment && clips.length > 0) {
          let activeTime = 0;
          clips.forEach( (clip, index) => {
            let clipDuration = (((index<clips.length-1) ?  clips[index+1].startTime : attachment.user_metadata.duration) - clip.startTime);
            if (clip?.active===true)
              activeTime += clipDuration
          })
          setTotalActiveTime(activeTime);

        //update the number of segments
        //we want to count the number of segments that will be submitted in the final video, so we ignore adjacent active clips
        let segmentCount = 0;
        let previousActive = false;
        clips.forEach((clip) => {
          if (clip?.active && !previousActive) {
            segmentCount++;
            previousActive = true;
          } else if (!clip?.active) {
            previousActive = false;
          }
        })
        setNumberOfSegments(segmentCount);
          
        //if any clips have had the active state changed, update the manifest
        console.log('checking verisons')
        if (versions[currentVersion-1]?.clips.length === clips.length) {
          if (versions[currentVersion-1].clips.some((clip, index) => clip?.active !== clips[index]?.active)) {
            console.log('updating manifest')
            updateManifest();
          }
        } else if (versions[currentVersion+1]?.clips.length === clips.length) {
          if (versions[currentVersion+1].clips.some((clip, index) => clip?.active !== clips[index]?.active)) {
            console.log('updating manifest')
            updateManifest();
          }
        }
      }

      //if the selected clip is not active, select the next active clip or the previous active clip
      if (!clips[selectedClip]?.active) {
        let nextActiveClip = clips.findIndex((clip, index) => clip?.active && index > selectedClip);
        let previousActiveClip = clips.slice(0, selectedClip).reverse().findIndex((clip) => clip?.active);
        if (nextActiveClip > -1) {
          setSelectedClip(nextActiveClip);
        } else if (previousActiveClip > -1) {
          setSelectedClip(clips.length - previousActiveClip - 1);
        } else {
          setSelectedClip(0);
        }
      }

      setKeypressListeners();

    }, [clips]);

    useEffect(() => {
      if (totalActiveTime > 1800)
        setMaxZoom(totalActiveTime / 80);
      else
        setMaxZoom(10);
    }, [totalActiveTime]);

    useEffect(() => {
        setKeypressListeners();
        return () => {
          removeKeypressListeners();
        }
    }, [selectedClip]);

    useEffect(() => {
      if (uploadIsOpen) {
        removeKeypressListeners();
      } else {
        removeKeypressListeners();
        setKeypressListeners();
      }
    }, [uploadIsOpen]);

    function setKeypressListeners() {
      document.onkeydown = (e) => {
        e.preventDefault();
        switch (e.key) {
          case 'ArrowLeft':
            // handleSetTime(playerRef.current.currentTime() - 1);
            playerRef.current.currentTime(playerRef.current.currentTime() - 1)
            break;
          case 'ArrowRight':
            // handleSetTime(playerRef.current.currentTime() + 1);
            playerRef.current.currentTime(playerRef.current.currentTime() + 1)
            break;
          case 'ArrowUp':
            // setZoom(zoom => Math.max(1, Math.min(10, zoom + 0.25)));
            // handleZoom(1)
            break;
          case 'ArrowDown':
            // setZoom(zoom => Math.max(1, Math.min(10, zoom - 0.25)));
            // handleZoom(-1)
            break;
          case 'Backspace' || 'Delete':
            //broken, selectedclip is not updating
            handleRemoveClip(e);
            break;
          case 's' || 'S':
            handleSplitClip(e);
            break;
          case ' ':
            handlePlayPause(e);
            break;
          // ctrl + z
          case 'z':
            if ((e.ctrlKey && e.shiftKey) || (e.metaKey && e.shiftKey)) {
              handleRedo(e);
            } else if (e.ctrlKey || e.metaKey) {
              handleUndo(e);
            }
            break;
          case 'Z':
            if (e.ctrlKey || e.metaKey) {
              handleRedo(e);
            }
            break;
          default:
            break;
        }
      }
    }
  
    function removeKeypressListeners() {
      document.onkeydown = null;
    }

    function handleSetThumbnail(newAttachmentID) {
      return getCurrentVideoFrame(playerRef, (dataURL) => {
        //upload the thumbnail to the attachment
        fetch(dataURL)
        .then(res => res.blob())
        .then(blob => uploadThumbnail(newAttachmentID, blob))
      })
    };

    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);

    const handleCancel = (e) => {
      e.preventDefault();
      if (!showCancelModal)
        setShowCancelModal(true);
      else {
        //close the modal
        setShowCancelModal(false);
      }
    };

    const handleSave = (e) => {
      e.preventDefault();
      removeKeypressListeners();
      if (!showSaveModal)
        setShowSaveModal(true);
      else {
        //close the modal
        setShowSaveModal(false);
      }
    };

    function renderCancelModal() {
      return (
        <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Draft Changes Saved!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Your changes have been synced to the cloud, and you can pick up where you left off at any time.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => {setShowCancelModal(false); handleCloseEditor();}}>
              Return to Viewer
            </Button>
            <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
              Continue Editing
            </Button>
          </Modal.Footer>
        </Modal>
      )
    }

    function renderSaveModal() {
      return (
        <Modal show={showSaveModal} onHide={() => {setShowSaveModal(false); setKeypressListeners();}} centered>
          <Modal.Header closeButton>
            <Modal.Title>Save this version? </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              After processing, this will compile your edits into a single video, which will appear in your video library.
              You will still have access to the original video, and will be able to edit it further.
            </p>
            <div>
              <input 
                type='text' 
                placeholder='Enter a title for your new video.' 
                value={subtitle} 
                onChange={(e) => setSubtitle(e.target.value)} 
                style={{
                  width: '100%', 
                  border: '1px solid rgba(200,200,200,.5)', 
                  padding: '5px', 
                  borderRadius: '5px', 
                  marginBottom: '10px',
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleSubmitEdits} disabled={subtitle.length===0}>
              Save New Video
            </Button>
            <Button variant="secondary" onClick={() => setShowSaveModal(false)}>
              Dismiss
            </Button>
          </Modal.Footer>
        </Modal>
      )
    }

    const handleCloseEditor = (redirectLink = `/viewer/${attachment_id}`) => {
      //close the editor
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      //remove the keypress listeners
      removeKeypressListeners();
      //dump the redux store
      dispatch(setCurrent({versionNumber: 0}));
      //report
      try {
        mixpanel.track('Closed Editing Session', 
          {
            'Source': 'Editor Page', 
            'Attachment ID': attachment_id,
          }
        );
      } catch (error) {
        Sentry.captureException(error);
      }
      //navigate to the viewer page
      navigate(redirectLink);
    }

    const handleSubmitEdits = (e) => {
      e.preventDefault();
      //save the edited version of the video

      //get the start and end times of all active clips, stored as {start_time: x, end_time: y}
      let activeClips = []
      clips.forEach((clip, index) => {
          if (clip?.active) {
            activeClips.push({
              "start_time": clip.startTime,
              "end_time": (index<clips.length-1) ? clips[index+1].startTime : attachment.user_metadata.duration
            })
          }
      })

      //merge any adjacent clips
      let mergedClips = [];
      activeClips.forEach((clip, index) => {
        if (index === 0) {
          mergedClips.push(clip);
        } else {
          if (clip.start_time === mergedClips[mergedClips.length-1].end_time) {
            mergedClips[mergedClips.length-1].end_time = clip.end_time;
          } else {
            mergedClips.push(clip);
          }
        }
      })
      activeClips = mergedClips;
      
      createAttachment(
        {
          "edited_from_id": attachment_id,
          "user_edits": {
              "clips": activeClips,
              "mute_audio": audioMuted,
          },
          "user_metadata": {
              "duration": totalActiveTime,
          },
          "subtitle": subtitle
      }
      ).then( newAttachment => {
        try {
          mixpanel.track('Saved New Attachment', 
            {
              'Source': 'Editor Page', 
              'Attachment ID': attachment_id,
              'New Attachment ID': newAttachment.id,
              'Number of Clips': activeClips.length,
              'Mute Audio': audioMuted,
              'Final Duration': totalActiveTime,
              'Percentage of Original': Math.round(totalActiveTime / attachment.user_metadata.duration*100),
            }
          );
        } catch (error) {
          Sentry.captureException(error);
        }
        //upload thumbnail - first frame
        return handleSetThumbnail(newAttachment.id)
      }).then(() => {
      //close the editor
      handleCloseEditor('/library');
      })
    }

    const [playerReady, setPlayerReady] = useState(false);
    const handlePlayerReady = (player) => {
      playerRef.current = player;
      setPlayerReady((playerReady) => true);
      // You can handle player events here, for example:
      player.on('waiting', () => {
        videojs.log('player is waiting');
      });
  
      player.on('dispose', () => {
        videojs.log('player will dispose');
      });

    };

    const handleSplitClip = (e) => {
      e.preventDefault();

      //if numberOfSegments is greater than 98, do nothing
      if (numberOfSegments > 98)
        return

      //add a new clip at the current time
      //accounting for the length of any inactive clips before the current time to the current time
      let newStartTime = Math.round(playerRef.current.currentTime());
      clips.forEach((clip, index) => {
        if (clip.startTime < newStartTime && !clip?.active) {
          let clipDuration = (((index<clips.length-1) ?  clips[index+1].startTime : attachment.user_metadata.duration) - clip.startTime);
          newStartTime += clipDuration;
        }
      });

      //if the current time is rounded to an already existing start time, do nothing
      if (clips.some(clip => clip.startTime === newStartTime)) {
        return;
      }

      dispatch(addCut({startTime: newStartTime}));
      try {
        mixpanel.track('Split Clip', 
          {
            'Source': 'Editor Page', 
            'Attachment ID': attachment_id,
            'Clip Number': selectedClipRef.current,
            'Number of Clips': clips.length,
            'Number of Segments': numberOfSegments
          }
        );
      } catch (error) {
        Sentry.captureException(error);
      }
    }

    const handleRemoveClip = (e) => {
      e.preventDefault();
      //if the numberOfSegments is > 98, do nothing
      if (numberOfSegments > 98)
        return

      //if the selected clip is the only active clip, do nothing
      if (clips.filter(clip => clip?.active).length === 1)
        return
      //remove the selected clip
      dispatch(setClipActive({clipNumber: selectedClipRef.current, isActive: false}));
      try {
        mixpanel.track('Removed Clip', 
          {
            'Source': 'Editor Page', 
            'Attachment ID': attachment_id,
            'Clip Number': selectedClipRef.current
          }
        );
      } catch (error) {
        Sentry.captureException(error);
      }
    }

    const handleUndo = (e) => {
      e.preventDefault();
      //undo the last action
      dispatch(setCurrent({versionNumber: currentVersion-1}));
    }

    const handleRedo = (e) => {
      e.preventDefault();
      //redo the last action
      dispatch(setCurrent({versionNumber: currentVersion+1}));
    }

    const handleRewind = (e) => {
      e.preventDefault();
      //rewind the video by 10 seconds
      playerRef.current.currentTime(playerRef.current.currentTime() - 10);
    }

    const handleSkipBack = (e) => {
      e.preventDefault();
      //rewind the video to the start of the current clip
      //if we are within the first second of a clip, rewind to the start of the previous clip

      //start by calculating the current clip
      let currentClip = 0;
      const currentTime = playerRef.current.currentTime();
      clips.forEach((clip, index) => {
        if (clip.modifiedStartTime <= currentTime && clip?.active) {
          currentClip = index;
        }
      })

      let time
      if ((currentTime - clips[currentClip].modifiedStartTime) < 1 && currentClip > 0) {
        //within the first second of the current clip
        let previousClip = currentClip-1;
        while (previousClip > 0 && !clips[previousClip]?.active) {
          previousClip--;
        }
        time=clips[previousClip].modifiedStartTime;
      } else {
        time=clips[currentClip].modifiedStartTime;
      }
      playerRef.current.currentTime(time);
    }

    const handleSkipForward = (e) => {
      e.preventDefault();
      //fastforward the video to the end of the current clip
      //if it's the last active clip, do nothing
      let currentClip = 0;
      const currentTime = playerRef.current.currentTime();

      if (Math.abs(playerRef.current.currentTime() > clips.filter(clip => clip?.active).slice(-1)[0].startTime-1))
        return
      
      clips.forEach((clip, index) => {
        if (clip.modifiedStartTime <= currentTime && clip?.active) {
          currentClip = index;
        }
      })
      playerRef.current.currentTime((currentClip<clips.length-1) ? clips[currentClip+1].modifiedStartTime : (playerRef.current.duration()));
    }

    const handlePlayPause = (e) => {
      e.preventDefault();
      //play or pause the video
      if (playerRef.current.paused()) {
        playerRef.current.play()
        setPlayPauseState('playing');
      }
      else {
        playerRef.current.pause()
        setPlayPauseState('paused');
      }
    }

    const handleZoom = (zoomAmount) => {
      //set the zoom level
      const newZoom = Math.max(1, Math.min(maxZoom, zoom + zoomAmount*zoom/2));
      setZoom(newZoom);
      clipsZoom.current = newZoom;

      //set the scroll position so that the current time is in the middle of the window
      let newScrollPosition = (playerRef.current.currentTime()/totalActiveTime) * timelineWindowRef.current.clientWidth * newZoom - timelineWindowRef.current.clientWidth * .5;

      //wait for the scroll position to update
      setTimeout(() => {
        timelineWindowRef.current.scrollLeft = newScrollPosition;
        clipsOffset.current = newScrollPosition;
      }, 20)

    }

    const handleAdvance = (e) => {
      e.preventDefault();
      //fastforward the video by 10 seconds
      playerRef.current.currentTime(playerRef.current.currentTime() + 10);
    }

    function updateManifest() {
      playerRef.current.pause();

      let previousTime = playerRef.current.currentTime();
      //calculate previous time in the ORIGINAL manifest
      versions[previousVersion].clips.forEach((clip, index) => {
        if (clip.startTime < previousTime && !clip?.active) {
          previousTime += (((index<clips.length-1) ?  clips[index+1].startTime : attachment.user_metadata.duration) - clip.startTime);
        }
      });

      //video
      let newSegments = videoSegments;
      //remove segments that are during the inactive clips
      clips.forEach((clip, index) => {
        if (!clip?.active) {
          newSegments = cutSegments(newSegments, clip.startTime, (index<clips.length-1) ? clips[index+1].startTime : attachment.user_metadata.duration);
        }
      });
      let newPlaylist = generateMediaManifest(videoHeader, newSegments);
      let blob = new Blob([newPlaylist], { type: 'application/vnd.apple.mpegurl' });
      const videoURL = URL.createObjectURL(blob);

      //audio
      let audioURL = null;
      if (videoHasSeperateAudio) {
        newSegments = audioSegments;
        //remove segments that are during the inactive clips
        clips.forEach((clip, index) => {
          if (!clip?.active) {
            newSegments = cutSegments(newSegments, clip.startTime, (index<clips.length-1) ? clips[index+1].startTime : attachment.user_metadata.duration);
          }
        });
        newPlaylist = generateMediaManifest(audioHeader, newSegments);
        blob = new Blob([newPlaylist], { type: 'application/vnd.apple.mpegurl' });
        audioURL = URL.createObjectURL(blob);
      }
      
      //master
      let newStreams = masterStreams;
      newStreams.forEach((stream) => {
        if (stream.type === 'video') {
          stream.uri = videoURL;
        } else if (stream.type === 'audio') {
          stream.uri = audioURL;
        }
      })

      newStreams = newStreams.filter((stream) => stream.type !== 'image');

      const newMasterManifest = generateMasterManifest(newStreams);
      const masterURL = URL.createObjectURL(new Blob([newMasterManifest], { type: 'application/vnd.apple.mpegurl' }));
      playerRef.current.src({
        src: masterURL,
        type: 'application/x-mpegURL'
      });

      let newTime=previousTime;
      //calculate the new time in the NEW manifest
      //some segments will be removed, so the time will be different
      clips.forEach((clip, index) => {
        if (clip.startTime < previousTime && !clip?.active) {
          newTime -= (((index<clips.length-1) ?  clips[index+1].startTime : attachment.user_metadata.duration) - clip.startTime);
        }
      });

      //set the time to the same position in the clip, accounting for any clips that have been enabled or disabled
      playerRef.current.currentTime(newTime);
    }

    return ( 
        <Container>
          <PageMain>
            {downloadLink && attachment && clips &&
              <PlayerContainer>
                <VideoPlayer
                  options={{
                    controls: false,
                    playsinline: true,
                    fill: true,
                    autoplay: false,
                    crossOrigin: 'use-credentials',
                    aspectRatio: '16:9',
                    enableSmoothSeeking: true,
                    // playbackRates: [1, 1.5, 2],
                    html5: {
                      vhs: {
                        "withCredentials": true,
                        "overrideNative": true,
                        "useDevicePixelRatio": true,
                      },
                      // nativeAudioTracks: false,
                      // nativeVideoTracks: false
                    } 
                  }}
                  src={downloadLink}
                  onReady={handlePlayerReady}
                  info={
                    {
                      attachment_id: attachment_id,
                      owner_id: attachment.case.owner.id,
                    }
                  }
                />
                  <EditorControlsContainer 
                  ref={editorRef}
                  >
                    <ControlButtonsContainer>
                    <div
                        style={{position: 'absolute', left:22, bottom:52, backgroundColor: 'rgba(0,0,0,.75)', zIndex:5, display: 'flex', flexDirection: 'row', gap: '20px', padding: '3px', borderRadius: '8px'}}
                    >
                      {audioMuted ?
                        <TooltipButton
                            src='/images/icons/player/volume-x.svg'
                            onClick={() => {setAudioMuted(false); playerRef.current.muted(false)}}
                            tooltipText='Unmute Audio'
                            style={{width: '18px'}}
                        />
                        :
                        <TooltipButton
                            src='/images/icons/player/volume-max.svg'
                            onClick={() => {setAudioMuted(true); playerRef.current.muted(true)}}
                            tooltipText='Mute Audio'
                            style={{width: '18px'}}
                        />
                        }
                    </div>
                    <div
                        style={{display: 'flex', flexDirection: 'row', gap: '20px', padding: '10px', width: '250px', justifyContent: 'center'}}
                    >
                        <TooltipButton
                            src='/images/icons/player/redo.svg'
                            disabled={currentVersion === 0}
                            style={{transform: 'rotateY(180deg)', width: '16px'}}
                            onClick={handleUndo}
                            tooltipText= {navigator.platform.toUpperCase().indexOf('MAC') >= 0 ? 'Undo (⌘+Z)' : 'Undo (Ctrl+Z)' }
                        />
                        <TooltipButton 
                            src='/images/icons/player/split.svg' 
                            disabled={numberOfSegments > 98}
                            onClick={handleSplitClip}
                            tooltipText={numberOfSegments < 99 ? 'Split Clip (S)' : 'Maximum number of clips reached.'}
                        />
                        <TooltipButton 
                            src='/images/icons/player/trash.svg' 
                            disabled={selectedClip === null || clips[selectedClip]?.active === false || clips.filter(clip => clip?.active).length === 1 || numberOfSegments > 98}
                            onClick={handleRemoveClip}
                            tooltipText= {numberOfSegments < 99 ? navigator.platform.toUpperCase().indexOf('MAC') >= 0 ? 'Delete Clip (Delete)' : 'Delete Clip (Backspace)' : 'Maximum number of clips reached.' }
                        />
                        <TooltipButton 
                            src='/images/icons/player/redo.svg'
                            style={{width: '16px'}}
                            disabled={currentVersion === numberOfVersions-1}
                            onClick={handleRedo}
                            tooltipText= {navigator.platform.toUpperCase().indexOf('MAC') >= 0 ? 'Redo (⌘+Shift+Z)' : 'Redo (Ctrl+Shift+Z)' }
                        />
                    </div>
                    {playerReady &&
                    <div
                        style={{display: 'flex', flexDirection: 'row', gap: '10px', padding: '10px', width: '200px', justifyContent: 'center'}}
                        >
                        <TooltipButton
                            src='/images/icons/player/skip-back.svg'
                            onClick={handleSkipBack}
                            tooltipText='Skip to Previous Clip'
                            disabled={playerRef.current.currentTime() < 1}
                        />
                        <TooltipButton
                            style={{transform: 'rotate(180deg)'}}
                            src='/images/icons/player/linear_ff.svg'
                            onClick={handleRewind}
                            tooltipText='Rewind (Left Arrow)'
                            disabled={playerRef.current.currentTime() < 1}
                        />
                        { playPauseState === 'paused' ?
                        <TooltipButton 
                        style={{padding: '0px 1px 0px 0px'}}
                            src='/images/icons/player/linear_play.svg' 
                            onClick={handlePlayPause}
                            tooltipText='Play (Spacebar)'
                        /> :
                        <TooltipButton
                            style={{padding: '2px 0px 2px 0px'}}
                            src='/images/icons/player/linear_pause.svg'
                            onClick={handlePlayPause}
                            tooltipText='Pause (Spacebar)'
                        />}
                        <TooltipButton 
                            src='/images/icons/player/linear_ff.svg' 
                            onClick={handleAdvance}
                            tooltipText='Advance (Right Arrow)'
                            disabled={Math.abs(playerRef.current.currentTime() - playerRef.current.duration()) < 1}
                        />
                        <TooltipButton
                            src='/images/icons/player/skip-forward.svg'
                            onClick={handleSkipForward}
                            tooltipText='Skip to Next Clip'
                            disabled={Math.abs(playerRef.current.currentTime() > clips.filter(clip => clip?.active).slice(-1)[0].startTime-1)}
                        />
                    </div>
                    }
                    <div
                      style={{display: 'flex', flexDirection: 'row', gap: '10px', padding: '10px', width: '250px', justifyContent: 'center'}}
                      >
                      {/* Display Time*/}
                      <div
                        style={{color: 'white', fontSize: '15px', fontWeight: 600, width: '100px', cursor: 'default', whiteSpace: 'nowrap', marginRight: '10px'}}
                        >
                        {hmsFromSeconds(displayTime)} / {hmsFromSeconds(totalActiveTime)}
                      </div>
                      <TooltipButton
                        src='/images/icons/player/zoom_out.svg'
                        style={{width: '20px'}}
                        onClick={() => handleZoom(-1)}
                        disabled={zoom <= 1}
                        tooltipText='Timeline Zoom Out (Scroll)'
                      />
                      <TooltipButton
                        src='/images/icons/player/zoom_in.svg'
                        style={{width: '20px'}}
                        onClick={() => handleZoom(1)}
                        disabled={zoom >= maxZoom}
                        tooltipText='Timeline Zoom In (Scroll)'
                      />
                    </div>
                    </ControlButtonsContainer>
                    {editorRef.current && thumbnails.length>0 &&
                      <TimelineElement 
                        attachment={attachment}
                        editorRef={editorRef}
                        playerRef={playerRef}
                        playerReady={playerReady}
                        clips={clips}
                        setDisplayTime={setDisplayTime}
                        selectedClip={selectedClip}
                        setSelectedClip={setSelectedClip}
                        selectedClipRef={selectedClipRef}
                        totalActiveTime={totalActiveTime}
                        thumbnails={thumbnails}
                        zoom={zoom}
                        setZoom={setZoom}
                        clipsOffset={clipsOffset}
                        clipsWidth={clipsWidth}
                        clipsZoom={clipsZoom}
                        maxZoom={maxZoom}
                        timelineWindowRef={timelineWindowRef}
                      />
                    }
                </EditorControlsContainer>
              </PlayerContainer>
            }
            {caseInfo && attachment &&
            <VideoDetailsContainer>
              <VideoBylineContainer>
                <VideoByline>
                  <h4>{ caseInfo.case_name }</h4>
                  <p>{ caseInfo.owner.first_name } { caseInfo.owner.last_name }</p>
                </VideoByline>
                { draftSavedAt !== null ? (
                  <span style={{"font-size": ".9em", "fontStyle": "italic", "color": "grey"}}>Draft synced to cloud <TimeAgo eventTime={draftSavedAt} justNow={true}/>.</span>
                ) : null }
                <ButtonGroup>
                  { attachment.permissions.can_edit ? 
                  <Button onClick={handleSave}>Save as New Video</Button> :
                  <OverlayTrigger 
                    placement='top'
                    delay={{ show: 100, hide: 100 }}
                    overlay={
                        <Tooltip>
                            You do not have permission to edit this shared video. Ask the video owner to update the sharing permissions for this video.
                        </Tooltip>
                            }
                    >
                  <span>
                  <Button disabled={true}>Save New Version</Button>
                  </span>
                  </OverlayTrigger>
                  }
                  <Button onClick={handleCancel}
                    style={{backgroundColor: 'black', borderColor: 'black'}}
                  >
                    Return to Viewer
                  </Button>
                </ButtonGroup>
              </VideoBylineContainer>
              <VideoDetails>
                  <b>
                      { dateFromTimestamp(caseInfo.created_on) } 
                      {/* { caseInfo.case_type && (' \u2022 ' + caseInfo.case_type) } */}
                      {attachment.user_metadata?.duration && 
                        ' \u2022 ' + hmsTextFromSeconds(attachment.user_metadata?.duration)
                      }
                      {(attachment.markers?.length > 0) && 
                          (`  \u2022  ` + attachment.markers?.length + 
                          ` chapter` + (attachment.markers?.length>1 ? `s` : ``))
                      }
                  </b>
                    {caseInfo.description && (caseInfo.description.split(" ").length > 12) ? (
                    <div align='left'>
                      {!expandDescription ? 
                      (<span>
                        {reduceStringWords(caseInfo.description, 12)}
                        <b><a onClick={() => setexpandDescription(true)}> ...more </a></b>
                      </span>) : (
                        <div>
                          <p align='justify'>{ caseInfo.description }</p>
                          <a onClick={() => setexpandDescription(false)}>Show Less</a>
                        </div>)}
                    </div>) : (<p align='justify'>{ caseInfo.description }</p>)}

              </VideoDetails>
            </VideoDetailsContainer>
            }
          </PageMain> 
          <PageRight>
              <TimelineContainer>
                {attachment && playerReady && <EditorTimeline playerRef={playerRef} attachment={attachment}/>}
              </TimelineContainer>
          </PageRight>
          <>{renderCancelModal()}</>
          <>{renderSaveModal()}</>
        </Container>
      );
}

const TooltipButton = ({ src, style, disabled, onClick, tooltipText }) => {

    return (
      <OverlayTrigger
        placement='top'
        delay={{ show: 500, hide: 100 }}
        overlay={
          <Tooltip 
            id='tooltip-top'
          >
            {tooltipText}
          </Tooltip>
        }
      >
        <ImgButton
          src={src}
          style={style}
          disabled={disabled}
          onClick={disabled ? null : onClick}
        />
      </OverlayTrigger>
    )
}


const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 12px;
    gap: 10px;
    margin: 0;
    width: 100%;
    max-width: 1920px;
    justify-content: center;

    @media (max-width: 899px) {
        position: relative;
        padding: 0;
        align-items: flex-start;
        overflow-y: auto;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;    
        gap: 0px;    
    }

    `;

const PageMain = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
    max-width: calc(75vh * 16/9);
    align-items: flex-start;
    justify-content: flex-start;

    @media (max-width: 899px) {
      max-width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      justify-self: flex-start;
    }
    `;

const PageRight = styled.div`
    display: flex;
    flex: 0 0 270px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    `;

const PlayerContainer = styled.div`
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 7px;
    overflow: hidden;

`;

const TimelineContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 270px;
    height: 100%;
    max-height: min(calc(9/16 * (100vw - 320px)), calc(9/16 * (75vh * 16/9 - 270px) - 10px));

    @media (max-width: 899px) {
      width: 100%;
      height: 100%;
    }
  `;

const VideoBylineContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
`;

const VideoByline = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const VideoDetailsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const VideoDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #f5f5f5;
    width: 100%;
    padding: 20px;
    border-radius: 7px;
    font-size: 15px;
    width: 100%;
`;

const EditorControlsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: inherit;
    height: 160px;
    background-color: black;
    margin-top: -1px;
    user-select: none;
    -webkit-user-select: none;
`;

const ControlButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    `;


const ImgButton = styled.img.attrs(
    (props) => ({
        style: {
          filter: props.disabled ? 'brightness(0) saturate(100%) invert(35%) sepia(6%) saturate(0%) hue-rotate(173deg) brightness(89%) contrast(85%)' : null,
        }
    }))`
    cursor: pointer;
    &:hover {
        filter: brightness(0) saturate(100%) invert(27%) sepia(97%) saturate(1852%) hue-rotate(185deg) brightness(101%) contrast(101%);
    }
`;

export default Editor;