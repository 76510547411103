import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import useScrollReporter from "../../hooks/useScrollReporter";
import ECSStore from "../../components/ECSStore";
import Carousel from 'react-bootstrap/Carousel';
import Accordion from 'react-bootstrap/Accordion';
import mixpanel from "mixpanel-browser";
import Helmet from "react-helmet";

const ECSClub = () => {

  useScrollReporter('Shop');

  const params = new URLSearchParams(window.location.search);

  React.useEffect(() => {
    mixpanel.track_pageview("Store");
  }, [])

  return (
    <>
    <Helmet>
      <meta 
        name="description"
        content="Get started with our head-mounted surgical camera and surgical video platform."
      />
      <meta name="robots" content="noindex, nofollow" />
      <title>Join ECS Club | Orchid Surgical</title>
    </Helmet>
    <StyledLandingPage>
      <HeaderBackground>
        <img src="/images/store/Landing/landing_bkg.svg" alt="Orchid Surgical" height ='100%'/>
      </HeaderBackground>
      <Header />
      <Panel style={{marginBottom: "0px"}}>
        <div style={{width: '100%', alignItems: 'center', textAlign: 'center', fontFamily: 'Lato', backgroundColor: '#f2f8ff', padding: "25px", borderRadius: "10px"}}>
            <img src="/images/store/ForSurgeons/ecs_club_banner.png" style={{'width': '100%', 'max-width': '250px'}} />
            <br /><br />
            <h1>Welcome, ECS Club!</h1>
            <p><b>Orchid is excited to host the 2025 ECS Video Competition!</b></p>
            <p>
                This year, submissions will work a little differently. All ECS Club meeting registrants will receive a free one-year Orchid Pro subscription!
                Once you're on Orchid, you can submit your video directly within the ECS Club Group. Take a look at the two ways you can join Orchid and enter 
                the video competition below, and please reach out to contact@orchidsurgical.com with any questions.
            </p>
        </div>
      </Panel>
      <Panel style={{"margin-bottom": "0px"}}>
        <div className='content-container'>
        <StyledCarousel data-bs-theme="dark" indicators={false}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/store/ForSurgeons/headset_front.jpg"
                alt="Headmounted Surgical Camera"
                style={{borderRadius: '10px'}}
              />
            </Carousel.Item>
            <Carousel.Item
              interval={10000}
            >
            <div
              style={{
                aspectRatio: '1/1',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <h2
                style={{
                  fontFamily: 'Lato',
                  fontWeight: '500',
                  textAlign: 'center',
                  width: '100%',
                }}
              >Filmed with Orchid</h2>
              <video autoPlay loop muted playsInline className="d-block w-100" style={{borderRadius: '10px'}}>
                <source src="/videos/store_sample.mp4" type="video/mp4" />
              </video>
              <p>Dr. Robert Farivar, MD, PhD | Saint Alphonsus Hospital (Idaho, USA)</p>
            </div>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/store/ForSurgeons/headset_back.jpg"
                alt="Headmounted Surgical Camera"
                style={{borderRadius: '10px'}}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/store/ForSurgeons/camera_angled.jpg"
                alt="Headmounted Surgical Camera"
                style={{borderRadius: '10px'}}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/store/ForSurgeons/box_open.jpg"
                alt="Headmounted Surgical Camera"
                style={{borderRadius: '10px'}}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/store/ForSurgeons/camera_surgeon.jpg"
                alt="Headmounted Surgical Camera"
                style={{borderRadius: '10px'}}
              />
            </Carousel.Item>
          </StyledCarousel>
        </div>
        <div className='text-container'>
          <ECSStore />
        </div>
      </Panel>
      <Panel style={{'height': '400px', 'width': '100%', 'padding': '0px', 'gap': '0px', 'margin-top': '50px'}}>
        <div className='text-container' style={{'background-color': 'white', 'display': 'flex', 'flex-direction': 'column', 'text-align': 'left', 'max-width': '50%', fontFamily: 'Lato'}}>
          <h2>Orchid Cloud</h2>
          <div style={{'display': 'flex', 'flex-direction': 'row', 'justify-content': 'center', 'width': '70%', whiteSpace: 'nowrap'}}>
            <div style={{'flex': '1', 'padding-left': '10px'}}>
              <p><img src="/images/store/ForSurgeons/icons/upload.svg" /> Unlimited video storage <ProTag /></p>
              <p><img src="/images/store/ForSurgeons/icons/tag.svg" /> Tagging and Annotation <ProTag /></p>
              <p><img src="/images/store/ForSurgeons/icons/edit.svg" /> Case video editing <ProTag /></p>
              <p><img src="/images/store/ForSurgeons/icons/share.svg" /> Simple case sharing <ProTag /></p>
            </div>
            <div style={{'flex': '1', 'padding-left': '10px'}}>
              <p><img src="/images/store/ForSurgeons/icons/permissions.svg" /> Edit and Download Permissions <ProTag /></p>
              <p><img src="/images/store/ForSurgeons/icons/watch.svg" /> Watch Shared Cases</p>
              <p><img src="/images/store/ForSurgeons/icons/compliant.svg" /> HIPAA Compliant</p>
              <p><img src="/images/store/ForSurgeons/icons/message.svg" /> Messaging</p>
            </div>
          </div>
        </div>
        <div className='text-container' style={{'background-color': '#f5f5f5', 'display': 'flex', 'flex-direction': 'column', 'text-align': 'left', 'max-width': '50%', fontFamily: 'Lato'}}>
          <h2>Your New Camera</h2>
          <div style={{'display': 'flex', 'flex-direction': 'row', 'justify-content': 'center', 'width': '90%', whiteSpace: 'nowrap'}}>
            <div style={{'flex': '1', 'padding-left': '10px'}}>
              <p><img src="/images/store/ForSurgeons/icons/battery.svg" /> 15 hrs (with included backup)</p>
              <p><img src="/images/store/ForSurgeons/icons/camera.svg" /> HD or 2k video</p>
              <p><img src="/images/store/ForSurgeons/icons/controls.svg" /> Brightness settings for any OR</p>
              <p><img src="/images/store/ForSurgeons/icons/microsd.svg" /> Records onto microSD (included)</p>
            </div>
            <div style={{'flex': '1', 'padding-left': '10px'}}>
              <p><img src="/images/store/ForSurgeons/icons/phone.svg" /> Streams to iOS/Android App</p>
              <p><img src="/images/store/ForSurgeons/icons/audio.svg" /> Optional audio recording</p>
              <p><img src="/images/store/ForSurgeons/icons/mounting.svg" /> Side or center mounting</p>
              <p><img src="/images/store/ForSurgeons/icons/wifi.svg" /> Connects to App over Wifi</p>
            </div>
          </div>
        </div>
      </Panel>
      <Panel style={{"padding": "0px", "text-align": "center", marginTop: "-50px"}}>
        <div className="text-container" style={{"width": "100%", "max-width": "100%", fontFamily: "Lato"}}>
          <h2 style={{fontWeight: "600"}}>What's In The Box?</h2>
          <img src="/images/store/ForSurgeons/in_the_box_big.png" style={{"width": "100%", "max-width": "800px"}} />
        </div>
      </Panel>
      <Panel style={{"padding": "40px 100px", marginTop: "-50px"}}>
        <div 
          style={{"width": "100%", "align-items": "left", "text-align": "left"}}
          onClick={()=>{
            mixpanel.track("Viewed FAQs");
          }}
        > 
          <h2 style={{fontFamily: "Lato", fontWeight: "600", marginBottom: "20px"}}>Frequently Asked Questions</h2>
          <Accordion>
          <Accordion.Item eventKey="0">
              <Accordion.Header>Does the Orchid Camera need to be plugged in to anything during use?</Accordion.Header>
              <Accordion.Body>
                <p>
                  No, the Orchid Camera is completely wireless. It has a battery life of 5 hours and records onto a MicroSD card (included). 
                  Get 10 extra hours of recording lifetime by plugging the camera into a belt-mounted USB power bank (also included).
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>How do I wear the camera if I already have a headlight?</Accordion.Header>
              <Accordion.Body>
                <p>
                  The Orchid Camera is designed to be worn on the same headband as your headlight. 
                  While it ships with a comfortable headset, the camera can be easily mounted to any headband.
                  The camera's mounting point swivels so you can adjust the angle of the camera to match the angle of your headlight.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Can I use the Orchid Camera without Orchid Cloud?</Accordion.Header>
              <Accordion.Body>
                <p>
                  Yes, the Orchid Camera can be used without Orchid Cloud. The camera records onto a MicroSD card (included) and can be played back on any computer.
                  However, the real power of the Orchid Camera is when it is paired with Orchid Cloud.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Who can view my cases on Orchid Cloud?</Accordion.Header>
              <Accordion.Body>
                <p>
                  After uploading your video to Orchid Cloud, you can invite anyone to view your case, even if they are not yet Orchid users! They will receive an email with a link to join the platform and view the case.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Panel>
      <Footer/>
    </StyledLandingPage>
    <MobileStyledLandingPage>
      <HeaderBackground>
        <img src="/images/store/Landing/landing_bkg.svg" alt="background shapes" />
      </HeaderBackground>
      <Header />
      {/* <h1 style={{'margin-top': '10px', 'margin-bottom': '10px', 'font-size': '2em', 'font-weight': '600', 'text-align': 'center', 'font-family': 'Lato'}}> 
        Join Orchid
      </h1> */}
      <Panel style={{marginBottom: "0px"}}>
        <div style={{width: '100%', alignItems: 'center', textAlign: 'center', fontFamily: 'Lato', backgroundColor: '#f2f8ff', padding: "25px", borderRadius: "10px"}}>
            <img src="/images/store/ForSurgeons/ecs_club_banner.png" style={{'width': '100%', 'max-width': '250px'}} />
            <br /><br />
            <h1>Welcome, ECS Club!</h1>
            <p><b>Orchid is excited to host the 2025 ECS Video Competition!</b></p>
            <p>
                This year, submissions will work a little differently. All ECS Club meeting registrants will receive a free one-year Orchid Pro subscription!
                Once you're on Orchid, you can submit your video directly within the ECS Club Group. Take a look at the two ways you can join Orchid and enter 
                the video competition below, and please reach out to contact@orchidsurgical.com with any questions.
            </p>
        </div>
      </Panel>
      <Panel>
      <div className='content-container'>
          <StyledCarousel data-bs-theme="dark">
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/store/ForSurgeons/headset_front.jpg"
                alt="Headmounted Surgical Camera"
                style={{borderRadius: '10px'}}
              />
            </Carousel.Item>
            <Carousel.Item
              interval={10000}
            >
            <div
              style={{
                aspectRatio: '1/1',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <h2
                style={{
                  fontFamily: 'Lato',
                  fontWeight: '500',
                  textAlign: 'center',
                  width: '100%',
                }}
              >Filmed with Orchid</h2>
              <video autoPlay loop muted playsInline className="d-block w-100" style={{borderRadius: '10px'}}>
                <source src="/videos/store_sample.mp4" type="video/mp4" />
              </video>
              <p>Dr. Robert Farivar, MD, PhD | Saint Alphonsus Hospital (Idaho, USA)</p>
            </div>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/store/ForSurgeons/headset_back.jpg"
                alt="Headmounted Surgical Camera"
                style={{borderRadius: '10px'}}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/store/ForSurgeons/camera_angled.jpg"
                alt="Headmounted Surgical Camera"
                style={{borderRadius: '10px'}}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/store/ForSurgeons/box_open.jpg"
                alt="Headmounted Surgical Camera"
                style={{borderRadius: '10px'}}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/store/ForSurgeons/camera_surgeon.jpg"
                alt="Headmounted Surgical Camera"
                style={{borderRadius: '10px'}}
              />
            </Carousel.Item>
          </StyledCarousel>
        </div>
        <div className='text-container' style={{'padding': '0px', 'text-align': 'center', 'font-family': 'Lato'}}>
          <ECSStore />
        </div>
      </Panel>
      <Panel>
        <div className='text-container' style={{'background-color': 'white', 'display': 'flex', 'flex-direction': 'column', 'text-align': 'left', fontFamily: 'Lato', lineHeight: '26px'}}>
          <h2>Orchid Cloud</h2>
          <table style={{'width': '100%', 'max-width': '360px', 'margin': 'auto'}}>
            <tr>
              <td style={{ paddingBottom: '40px', paddingRight: '7px', "vertical-align" : "text-top" }}><img src="/images/store/ForSurgeons/icons/upload.svg" /></td><td style={{"vertical-align" : "text-top"}}> <span>Unlimited video storage <ProTag /></span></td>
              <td style={{ paddingRight: '7px', paddingLeft: '10px', "vertical-align" : "text-top" }}><img src="/images/store/ForSurgeons/icons/permissions.svg" /></td><td style={{"vertical-align" : "text-top"}}><span>Edit and Download Permissions <ProTag /></span></td>
            </tr>
            <tr>
              <td style={{ paddingBottom: '40px',paddingRight: '7px', "vertical-align" : "text-top" }}><img src="/images/store/ForSurgeons/icons/tag.svg" /></td><td style={{"vertical-align" : "text-top"}}><span>Tagging and Annotation <ProTag /></span></td>
              <td style={{ paddingRight: '7px', paddingLeft: '10px', "vertical-align" : "text-top" }}><img src="/images/store/ForSurgeons/icons/watch.svg" /></td><td style={{"vertical-align" : "text-top"}}><span>Watch Shared Cases</span></td>
            </tr>
            <tr>
              <td style={{ paddingBottom: '40px',paddingRight: '7px', "vertical-align" : "text-top" }}><img src="/images/store/ForSurgeons/icons/edit.svg" /></td><td style={{"vertical-align" : "text-top"}}><span>Case video <br/> editing <ProTag /></span></td>
              <td style={{ paddingRight: '7px', paddingLeft: '10px', "vertical-align" : "text-top" }}><img src="/images/store/ForSurgeons/icons/compliant.svg" /></td><td style={{"vertical-align" : "text-top"}}><span>HIPAA Compliant</span></td>
            </tr>
            <tr>
              <td style={{ paddingRight: '7px', "vertical-align" : "text-top" }}><img src="/images/store/ForSurgeons/icons/share.svg" /></td><td style={{"vertical-align" : "text-top"}}><span>Simple case sharing <ProTag /></span></td>
              <td style={{ paddingRight: '7px', paddingLeft: '10px', "vertical-align" : "text-top" }}><img src="/images/store/ForSurgeons/icons/message.svg" /></td><td style={{"vertical-align" : "text-top"}}><span>Messaging</span></td>
            </tr>
        </table>
        </div>
        <div className='text-container' style={{'background-color': '#f5f5f5', 'display': 'flex', 'flex-direction': 'column', 'text-align': 'left', fontFamily: 'Lato', 'padding-bottom': '40px'}}>
          <h2 style={{paddingTop: '30px'}}>Your New Camera</h2>
          <table style={{'width': '100%', 'max-width': '360px', 'margin': 'auto'}}>
            <tr>
              <td style={{ paddingBottom: '40px',paddingRight: '7px', "vertical-align" : "text-top"  }}><img src="/images/store/ForSurgeons/icons/battery.svg" /></td><td style={{"vertical-align" : "text-top"}}> <span>15 hrs (with included backup)</span></td>
              <td style={{ paddingRight: '7px', paddingLeft: '10px', "vertical-align" : "text-top"  }}><img src="/images/store/ForSurgeons/icons/phone.svg" /></td><td style={{"vertical-align" : "text-top"}}><span>Streams to iOS/Android App</span></td>
            </tr>
            <tr>
              <td style={{ paddingBottom: '40px',paddingRight: '7px', "vertical-align" : "text-top"  }}><img src="/images/store/ForSurgeons/icons/camera.svg" /></td><td style={{"vertical-align" : "text-top"}}><span>HD or 2k video</span></td>
              <td style={{ paddingRight: '7px', paddingLeft: '10px', "vertical-align" : "text-top"  }}><img src="/images/store/ForSurgeons/icons/audio.svg" /></td><td style={{"vertical-align" : "text-top"}}><span>Optional audio recording</span></td>
            </tr>
            <tr>
              <td style={{ paddingBottom: '40px',paddingRight: '7px', "vertical-align" : "text-top"  }}><img src="/images/store/ForSurgeons/icons/controls.svg" /></td><td style={{"vertical-align" : "text-top"}}><span>Brightness settings <br/>for any OR</span></td>
              <td style={{ paddingRight: '7px', paddingLeft: '10px', "vertical-align" : "text-top"  }}><img src="/images/store/ForSurgeons/icons/mounting.svg" /></td><td style={{"vertical-align" : "text-top"}}><span>Side or center mounting</span></td>
            </tr>
            <tr>
              <td style={{ paddingBottom: '40px',paddingRight: '7px', "vertical-align" : "text-top"  }}><img src="/images/store/ForSurgeons/icons/microsd.svg" /></td><td style={{"vertical-align" : "text-top"}}><span>Records onto <br/> microSD (included)</span></td>
              <td style={{ paddingRight: '7px', paddingLeft: '10px', "vertical-align" : "text-top"  }}><img src="/images/store/ForSurgeons/icons/wifi.svg" /></td><td style={{"vertical-align" : "text-top"}}><span>Connects to App over Wifi</span></td>
            </tr>
          </table>
        </div>
      </Panel>
      <Panel style={{"padding": "0px", "text-align": "center", marginTop: "0px"}}>
        <div className="text-container" style={{"width": "100%", "max-width": "100%", fontFamily: "Lato"}}>
          <h2 style={{fontWeight: "600"}}>What's In The Box?</h2>
          <img src="/images/store/ForSurgeons/itb_case.png" style={{"width": "100%", "max-width": "320px"}} />
          <img src="/images/store/ForSurgeons/itb_headset.png" style={{"width": "100%", "max-width": "340px"}} />
          <img src="/images/store/ForSurgeons/itb_camera.png" style={{"width": "100%", "max-width": "140px"}} />
          <img src="/images/store/ForSurgeons/itb_battery.png" style={{"width": "100%", "max-width": "140px"}} />
          <img src="/images/store/ForSurgeons/itb_power.png" style={{"width": "100%", "max-width": "320px"}} />
          <img src="/images/store/ForSurgeons/itb_microsd.png" style={{"width": "100%", "max-width": "320px"}} />
        </div>
      </Panel>
      <Panel style={{"padding": "10px", "max-width": "100%", marginTop: "0px"}}>
        <div 
          style={{"width": "100%", "align-items": "left", "text-align": "center"}}
          onClick={()=>{
            mixpanel.track("Viewed FAQs");
          }}
        > 
          <h2 style={{fontFamily: "Lato", fontWeight: "600", marginBottom: "20px"}}>Frequently Asked Questions</h2>
          <Accordion style={{textAlign: "left"}}>
          <Accordion.Item eventKey="0">
              <Accordion.Header>Does the Orchid Camera need to be plugged in to anything during use?</Accordion.Header>
              <Accordion.Body>
                <p>
                  No, the Orchid Camera is completely wireless. It has a battery life of 5 hours and records onto a MicroSD card (included). 
                  Get an extra 10 hours of recording lifetime by plugging the camera into a belt-mounted USB power bank (also included).
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>How do I wear the camera if I already have a headlight?</Accordion.Header>
              <Accordion.Body>
                <p>
                  The Orchid Camera is designed to be worn on the same headband as your headlight. 
                  While it ships with a comfortable headset, the camera can be easily mounted to any headband.
                  The camera's mounting point swivels so you can adjust the angle of the camera to match the angle of your headlight.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Can I use the Orchid Camera without Orchid Cloud?</Accordion.Header>
              <Accordion.Body>
                <p>
                  Yes, the Orchid Camera can be used without Orchid Cloud. The camera records onto a MicroSD card (included) and can be played back on any computer.
                  However, the real power of the Orchid Camera is when it is paired with Orchid Cloud.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Who can view my cases on Orchid Cloud?</Accordion.Header>
              <Accordion.Body>
                <p>
                  After uploading your video to Orchid Cloud, you can invite anyone to view your case, even if they are not yet Orchid users! They will receive an email with a link to join the platform and view the case.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Panel>
      <Footer/>
    </MobileStyledLandingPage>
    </>
  );
};

export default ECSClub;

export const StyledLandingPage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;

  @media only screen and (max-width: 900px) {
    display:none;
  }

`;

export const MobileStyledLandingPage = styled.div`
  @media only screen and (min-width: 900px) {
    display: none;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
`;

export const HeaderBackground = styled.div`
  position: absolute;
  width: 720px;
  height: auto;
  z-index: -10;
  right: 0;
  top: 0;

  img {
    height: 100%;
    width: 100%;
  }

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const BackgroundShapes = styled.img`
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  width: 135%;
  height: 135%;
  transform: translate(-50%, -50%);
  object-fit: contain;
`;

export const Panel = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-around; */
  width: 100%;
  height: 100%;
  padding: 0 50px;
  gap: 100px;
  margin-top: 20px;
  margin-bottom: 100px;
  max-width: 1600px;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    padding: 0 10px;
    gap: 40px;
    margin-bottom: 40px;
  }

  /* border: 1px solid black; */
  .text-container {
    flex: 1 1 0;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    white-space: wrap;
    word-wrap: break-word;
    text-align: left;
    width: 100%;
    height: 100%;
    gap: 20px;
    max-width: 800px;
    flex-wrap: nowrap;
    /* border: 1px solid red; */
  }

  .content-container {
    position: relative;
    flex: 1 1 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 0;
    /* border: 1px solid blue; */
  }

`;

const ProTag = styled.span`
  background-color: #FDAD00;
  color: black;
  padding: 2px 4px;
  font-size: 0.7em;
  border-radius: 5px;
  position: relative;
  top: -7px;

  &::before {
    content: "Pro";
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #444A9F !important;
  font-size: 18px;
  font-weight: 600;

`;

const StyledCarousel = styled(Carousel)`

    display: flex;
    flex: 1 0 360px;
    min-width: 360px;
    max-width: 500px;

    .carousel-control-prev {
      left: -4rem; /* Adjust the spacing of the previous arrow */
    }

    .carousel-control-next {
      right: -4rem; /* Adjust the spacing of the next arrow */
    }

    .carousel-indicators {
      bottom: -40px;
    }

`;
