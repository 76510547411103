import React, {useState, useEffect} from "react";
import { styled } from "styled-components";
import Form from 'react-bootstrap/Form';
import { useParams, useNavigate } from "react-router-dom";
import { resetPassword } from "../services/api/authentication";
import Button from '../store/components/Button.js';
import Spinner from 'react-bootstrap/Spinner';
import mixpanel from 'mixpanel-browser';
import { gtag_id, gtag_config } from "../sources.js";
import { setUser } from "../services/redux/userSlice.js";
import { useDispatch } from 'react-redux';
import Header from "../store/components/Header.js";
import { HeaderBackground } from "../store/pages/LandingPage.js";
import { GlassContainer } from "../store/components/GlassContainer.js";
import * as Sentry from "@sentry/react";

const ResetPassword = (props) => {

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [userData, setUserData] = useState({});

    const [passwordErrors, setPasswordErrors] = useState([]);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

    const navigate = useNavigate();

    let url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.search);
    const token = urlParams.get('code');
    const email = urlParams.get('email');
    const isInvite = urlParams.get('invite') === 'true';
    const next = urlParams.get('next');

    useEffect(() => {
        mixpanel.track_pageview('Reset Password');
    }, [])

    const handleInput = (e) => {
        let attribute = e.target.name;
        let value = e.target.value;
        setUserData({...userData, [attribute]: value});
    }

    const validatePassword = () => {
        let pwErrors = [];

        if (userData.password) {
            // check password meets requirements
            if (userData.password.length < 8) {
                pwErrors.push('Password must be at least 8 characters long.');
            }
            if (!userData.password.match(/[A-Z]/)) {
                pwErrors.push('Password must contain at least one uppercase letter.');
            }
            if (!userData.password.match(/[a-z]/)) {
                pwErrors.push('Password must contain at least one lowercase letter.');
            }
            if (!userData.password.match(/[0-9]/)) {
                pwErrors.push('Password must contain at least one number.');
            }
            if (!userData.password.match(/[!@#$%^&\-+=\*]/)) {
                pwErrors.push('Password must contain at least one special character.');
            }
        }

        return pwErrors;
    }

    useEffect(() => {
        let pwErrors = validatePassword();
        setPasswordErrors(pwErrors);

        let submitEnabled = (
            (userData.password && pwErrors.length === 0) &&
            ((isInvite && (userData.first_name && userData.first_name !== "") && (userData.last_name && userData.last_name !== "")) || !isInvite)
        );
        setIsSubmitEnabled(submitEnabled);
    }, [userData]);

    const handleSubmit = async e => {
        e.preventDefault();
        resetPassword(email, token, userData.password, userData.first_name, userData.last_name).then(data => {
            if (data) {
                let _successMessage = isInvite ? "Password set successfully! Redirecting to login page..." : "Password reset successfully! Redirecting to login page...";
                setSuccessMessage(_successMessage);
                setTimeout(() => {
                    let url = '/login/';
                    if (next) {
                        url += '?next=' + next;
                    }
                    navigate(url);
                }
                , 1000);
                try {
                    if (isInvite) {
                        mixpanel.identify(data.id);
                        mixpanel.people.set({
                            "$name": userData.first_name + " " + userData.last_name,
                            "$email": email,
                            "$tier": "free",
                            "$role": "surgeon",
                            "Signup Date": new Date(),
                            "Source": "Invite"
                        });
                        mixpanel.track("Accepted Invite", {
                            "Email": email,
                            "Name": userData.first_name + " " + userData.last_name,
                        });
                    } else {
                        mixpanel.identify(data.id);
                        mixpanel.track("Password Reset", {
                            "email": email
                        });
                    }
                } catch (error) {
                    Sentry.captureException(error);
                }
            }
            else {
                setSuccessMessage("")
                setErrorMessage("There was an error resetting your password. \n Please try again.")
            }
        })
    }


    return ( 
        <PageContainer>
        <HeaderBackground>
                <img src="/images/store/Landing/landing_bkg.svg" alt="background shapes" />
            </HeaderBackground>
            <Header />
            {/* <div style={{display: 'flex', height: "max(50vh, 300px)", alignSelf: "center"}}> */}
            <div style={{display: 'flex', height: "100%", alignItems: "center"}}>
            <div style={{display: 'flex', minHeight: "50vh", width: "60vw"}}>
            <GlassContainer>
            <Wrap>
            <FormWrapper>
            <Form onSubmit={handleSubmit} style={{width: "90%", maxWidth: '250px'}}>
                { isInvite ? (
                    <div>
                        <h4>Welcome!</h4>
                        <Explanation>
                            Create an account to get started.
                            <br/>
                            <br/>
                        </Explanation>
                        <Form.Group controlId="formBasicFirstName">
                            <Form.Control type="text" placeholder="First Name" onChange={handleInput} name="first_name" />
                        </Form.Group><br />
                        <Form.Group controlId="formBasicLastName">
                            <Form.Control type="text" placeholder="Last Name" onChange={handleInput} name="last_name" />
                        </Form.Group><br />
                    </div>
                ) : (null)}
                <Explanation>Please choose a new password:</Explanation>
                <Form.Group controlId="formBasicEmail">
                    <br/>
                    <Form.Control type="password" placeholder="New Password" onChange={handleInput} name="password" />
                    { userData.password && passwordErrors.length > 0 ? (
                        <div>
                            <ErrorList>
                            {passwordErrors.map((error, index) => (
                                <ErrorItem key={index}>{error}</ErrorItem>
                            ))}
                            </ErrorList>
                        </div>
                    ) : (null)}
                </Form.Group><br/>
                <Button 
                    text={isInvite ? "Set Password" : "Reset Password"}
                    color="#3D85BB" 
                    textColor="white" 
                    type="submit" 
                    disabled={!isSubmitEnabled}
                    />
            </Form>
            <br/>
            <p>{successMessage}{errorMessage}</p>
            </FormWrapper>
            </Wrap>
        </GlassContainer>
        </div>
        </div>
    </PageContainer>

     );
}

const PageContainer = styled.section`
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: calc(100vh - 82px);
    min-height: 600px;
    width: 100vw;
`;

const Wrap = styled.div`
    background-color: rgba(250, 250, 250, 0.6);
    position: relative;
    height: 100%;
    min-height: 290px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    border-radius: 10px;

    a {
        color: #007bff;
        font-size: 13px;
        font-style: underline;
    }

    h4 {
        color: #3D85BB;
    }
`;

const FormWrapper = styled.div`
    /* width: 400px; */
    background-color: rgba(250, 250, 250, 0.6);
    position: relative;
    height: 100%;
    min-height: 290px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    border-radius: 10px;
    padding: 15px 0px;
`;

const Explanation = styled.span`
    text-align: center;
    width: 90%;
    max-width: 500px;
    font-size: .9em;
`;

const ErrorList = styled.ul`
    list-style-type: none;
    padding-top: 5px;
    font-size: 0.8rem;
`;

const ErrorItem = styled.li`
    list-style-type: '❌ ';
    color: red;
`;

export default ResetPassword;