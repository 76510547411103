import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { getPublicKey, getCheckoutSessionEcsClub } from '../services/api/stripe_cli';
import styled from 'styled-components';
import { COLORS } from '../values/colors';
import { url_api, api_version } from '../sources.js';
import Button from '../store/components/Button';
import Form from '../store/components/Form';
import mixpanel from 'mixpanel-browser';
import * as Sentry from "@sentry/react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ECSStore = (props) => {

    const [stripe, setStripe] = useState(null);
    const [showWaitlist, setShowWaitlist] = useState(false);
    const [emailError, setEmailError] = useState(null);
    const [country, setCountry] = useState(null);
    const [isInternational, setIsInternational] = useState(false);
    const navigate = useNavigate();

    const user = useSelector(state => state.user.value);

    useEffect(() => {
        getPublicKey().then(data => {
            loadStripe(data.public_key).then(stripe => {
                setStripe(stripe);
            });
        });

        fetch("https://ipinfo.io/json?token=a83a6e1425dee9")
        .then((response) => response.json())
        .then((response) => {
            setCountry(response.country);
            setIsInternational(response.country != 'US' && response.country != 'CA');
        })
        .catch((error) => {
            setCountry('US');
            Sentry.captureException(error);
        });

    }, []);

    const handleJoinClick = (e) => {
        e.preventDefault();

        mixpanel.track('ECS Join Clicked', {});
        handleStripeCheckoutRedirect(e);
        mixpanel.track('Redirected to Stripe', {});
    }

    
    const handleStripeCheckoutRedirect = (e) => {
        e.preventDefault();
        let checkoutMode = e.currentTarget.getAttribute('checkoutmode');

        //if international OR countryError is true, redirect to international checkout
        getCheckoutSessionEcsClub(checkoutMode, isInternational).then((session) => {
            stripe.redirectToCheckout({
                sessionId: session.id
            });
        });
    }

    const handleNotifyMe = (e) => {
        e.preventDefault();
        let email = e.target.parentElement.querySelector('input').value;
        email = email.trim();
        email = email.toLowerCase();

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email.toLowerCase())) {
            setEmailError("Please enter a valid email address.");
            return;
        }

        e.target.parentElement.querySelector('input').disabled = true;
        e.target.parentElement.querySelector('Button').disabled = true;
        e.target.parentElement.querySelector('Button').innerText = "Sending...";

        // send to backend
        fetch(url_api + api_version + '/freshworks/notify_me/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({email: email}),
        }).then(response => {
            if (response.status === 200) {
                setEmailError(null);
                e.target.parentElement.querySelector('input').value = '';
                e.target.parentElement.querySelector('input').placeholder = "Thank you!";
                e.target.parentElement.querySelector('Button').innerText = "Notify Me";
            } else {
                e.target.parentElement.querySelector('input').disabled = false;
                e.target.parentElement.querySelector('Button').disabled = false;
                e.target.parentElement.querySelector('Button').innerText = "Notify Me";
                setEmailError("An error occurred. Please try again.");
            }
        })
    }

    return (
        <StoreContainer>
            <OptionsContainer align={props.align}>
                <OptionBox>
                    <PromotionBox text="ECS Club Price" />
                    <h3>Platform Only</h3>
                    <FeatureList>
                        <li><b>No Camera</b></li>
                        <li>Full Orchid Cloud Access</li>
                        <SubList>
                            <li>Unlimited case storage</li>
                            <li>Case editing</li>
                            <li>Case sharing</li>
                        </SubList>
                    </FeatureList>
                    <CheckoutBox>
                        <p>
                            <span style={{textDecoration: "line-through"}}>$240/year</span> $0 first year
                        </p>
                        <Button
                            color="black"
                            textColor="white"
                            checkoutmode="platform_only"
                            onClick={handleJoinClick}
                            text={props.checkoutText}
                            width={"100%"}
                        />
                    </CheckoutBox>
                </OptionBox>
                <OptionBox>
                    <PromotionBox text="ECS Club Price" style={{border: "2px solid #FDAD00", borderTop: "25px solid #FDAD00"}} />
                    <h3>Professional</h3>
                    <FeatureList>
                        <li><b>Orchid Camera</b></li>
                        <li>Full Orchid Cloud Access</li>
                        <SubList>
                            <li>Unlimited case storage</li>
                            <li>Case editing</li>
                            <li>Case sharing</li>
                        </SubList>
                    </FeatureList>
                    <CheckoutBox id="camera-bundle-price-box">
                        <p>
                            <b>$300</b> +<br /><span style={{textDecoration: "line-through"}}>$240/year</span> $0 first year
                        </p>
                        {showWaitlist &&
                        <ErrorMessage>Unable to checkout at this time.</ErrorMessage>
                        }
                        <Button
                            color="black"
                            textColor="white"
                            checkoutmode="platform_camera_bundle"
                            onClick={handleJoinClick}
                            text={props.checkoutText}
                            width={"100%"}
                        />
                    </CheckoutBox>
                </OptionBox>
            </OptionsContainer>
        </StoreContainer>
    );
}

export default ECSStore;

ECSStore.defaultProps = {
    checkoutText: "Checkout",
    align: "center",
}

const StoreContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const OptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: "${props => props.align}";
    justify-content: center;
    padding: 20px;
    width: 100%;
    height: 100%;
    gap: 40px 20px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const OptionBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    /* margin: 10px; */
    max-width: 300px;
    min-height: 320px;
    border: 1px solid darkgray;
    position:relative;
    white-space: nowrap;
    color:rgb(80, 80, 80);
    font-size: 1.1em;

    h3 {
        font-family: 'Poppins';
        font-size: 1.5em;
        font-weight: bold;
    }
`;

const Promotion = styled.div`
    background-color: #FDAD00;
    color: #000;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: .8em;
    text-align: center;
`;

const PromotionBox = styled.div`
    position: absolute;
    /* border: 2px solid ${COLORS.orchid_blue}; */
    border: 2px solid #0D6EFD;
    /* border-top: 26px solid ${COLORS.orchid_blue}; */
    border-top: 26px solid #0D6EFD;
    padding: 0px;
    margin-top: -21px;
    width: calc(100% + 4px);
    height: calc(100% + 25px);
    font-size: 1.1em;
    border-radius: 10px;
    pointer-events: none;
    font-weight: bold;
    white-space: nowrap;

    &::before {
        content: "${props => props.text}";
        position: absolute;
        top: -24px;
        left: 50%;
        transform: translateX(-50%);
        padding: 0 5px;
        font-size: 0.8em;
        color: white;
        border: none;
    }
`;

const FeatureList = styled.ul`
    width: 100%;
    margin-top:10px;
    text-align: left;
    list-style-type: none;
    margin-left:-40px;

    li {
        padding: 3px;
    }
`;

const SubList = styled.ul`
    width: 100%;
    margin-top: 0px;
    text-align: left;
    list-style-type: none;
    margin-left: -20px;
    font-size: .9em;

    li {
        padding: 3px;
    }
`;

const CheckoutBox = styled.div`
    width: 100%;
    margin-top: auto;
    text-align: left;
    padding: 0px 13px;
    margin-bottom: -10px;
`;

const ErrorMessage = styled.p`
    position: absolute;
    color: darkred;
    text-align: center;
    width: 100%;
    top: -35px;
    font-size: 1.1em; 
`;