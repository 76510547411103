import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { use } from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";

//This page contains an FAQ section and a contact form
const Help = (props) => {

    useEffect(() => {
        document.title = "FAQ | Orchid Surgical";
        mixpanel.track('Viewed Help Page');
    }
    , []);

    return ( 
        <Container>
            <h1>FAQs</h1>
            <FAQContainer>
            <h3>About My Orchid Camera</h3>
            <p>
                <b>How do I turn on my camera?</b><br/>
                Press and hold the power button until the LED begins blinking green. 
                When the LED turns solid green, the camera is ready to record.
            </p>
            <p>
                <b>Where can I find the camera's companion app?</b><br/>
                The Orchid Camera has a companion app that supports a live streaming preview and 
                settings. Download QS Camera for <a href="https://apps.apple.com/us/app/qs-camera/id1554952725">iOS</a> or <a href="https://play.google.com/store/apps/details?id=cn.rxt.qscase&pcampaignid=web_share">Android</a>.
            </p>
            <p>
                <b>How do I pair my camera with the companion app?</b><br/>
                When the camera is on, enable its WiFi hotspot by pressing the power/WiFi button once. 
                The LED will blink blue while the camera is in WiFi mode. The camera's network is named <i>Orchid_#######</i> and 
                its default password is <i>1234567890</i>. Connect to the camera's WiFi network from your smart device, 
                open the QS Camera app, and click <i>Connect WiFi</i>. You should now see a live preview from
                the camera, and be able to adjust the camera's settings.
            </p>
            <p>
                <b>How do I start a recording?</b><br/>
                When streaming to the companion app, press the record button in the app to start and stop recordings.
                If the camera is NOT paired over WiFi, you can use the record button on the top of the camera to start and stop recording. 
                The LED will blink green while the camrea is recording. The camera does not need to be paired 
                (or remain paired) in order to record video.
            </p>
            <p>
                <b>Where does my camera store video?</b><br/>
                Your Orchid camera saves video data to a microSD card, which is located
                at the rear of the camera, next to the charging port. Press on the card to eject it. 
                You can read the microSD card with your computer (e.g. using the USB-C adapter that shipped
                with your camera). Navigate to the <i>Videos</i> folder to see your videos. The
                Orchid camera separates recordings into 4GB video chunks, so you may see multiple files
                per recording, labeled by date and time. The Orchid Cloud platform will automatically stitch these files together
                into single case videos.
            </p>
            <p>
                <b>How do I keep my camera from running out of storage?</b><br/>
                The microSD card that shipped with your Orchid camera has enough memory to accomodate 60+ hours
                of continuous filming in Full HD (1080p). After you film a case, upload the files to the Orchid cloud (following the
                instructions below). You may then delete the video from your microSD card to make room for more recordings.
            </p>
            <p>
                <b>How do I charge my camera?</b><br/>
                Connect the camera to a power source using the USB-C cable that shipped with your camera. The camera's LED
                will be red while the camera is charging. The has about 5 hours of battery life when fully charged. The extended
                battery that shipped with your camera has an additional 10 hours of battery capacity when fully charged.
            </p>
            <p>
                <b>How do I adjust the camera for varying brightness levels in my OR?</b><br/>
                While the Orchid Camera automatically adjusts brightness levels, you can manually adjust the camera's 
                "Exposure Compensation" in the settings menu in the QS Camera app. If you find your videos are too dark,
                try adjusting this setting to +1; if they are too washed out, try setting it to -1.
            </p>
            </FAQContainer>
            <FAQContainer>
            <h3>About the Orchid Cloud Platform</h3>
            <p>
                <b>How can I upload video to the Orchid cloud?</b><br/>
                If you are an Orchd Pro user, click the upload icon on your sidebar. Open the <i>Videos</i> folder on your microSD 
                card (refer to the instructions above), select all the files from a single recording session, and drag them into the upload area on the Upload dialog.
                The Orchid cloud platform will automatically stitch all the video files in your upload into a single video.
                You may begin new uploads while a previous upload is still in progress. Uploads that are interrupted may be resumed when you return to the platform, 
                so long as the video files are still available on your microSD card. Orchid currently supports video files in the mp4 format. 
                For the best upload experience, we recommend uploading over a wired internet connection, while your computer is plugged in.
            </p>
            <p>
                <b>How can I edit my videos?</b><br/>
                Your video is stored on the Orchid platform. You can access recently uploaded videos from the Home page,
                and all of your videos are available on the library page. Once you select a video, you can annotate or chapterize it using the tools on the 
                right side of the video player. The Orchid platform also features cloud-based video editing tools, which you can access by clicking the 'Edit' 
                button on the video player.
            </p>
            <p>
                <b>How do I share my videos?</b><br/>
                From the Viewer page of a video, click the 'Share' button. You can share the video directly with any user or group on the Orchid platform. 
                You can share the video with a user outside of the Orchid platform by entering their email address, which will send them an email with a link
                to view the video, or by generating a publicly shareable link. 
            </p>
            <p>
                <b>How does the Orchid cloud protect patient privacy?</b><br/>
                The Orchid cloud platform is designed to be fully HIPAA-compliant. All video
                uploaded to the platform is encrypted at rest and in transit. Video is only
                accessible to users who have been granted access by the video owner. It is the video owner's
                responsibility to ensure that either (1) videos are only shared with a patient's care team, or (2) the 
                patient has given written consent to share the video outside of their care team, or (3) the video and its
                accompanying case details do not contain any identifying information. <br/><br/>
                Please see our <u><a href='\docs\Orchid-Surgical-Privacy-Policy.pdf' download>Platform Privacy Policies</a></u>, 
                our <u><a href='\docs\Orchid-Surgical-HIPAA-Privacy-Policies-and-Tags.pdf' download>HIPAA Privacy Policies</a></u>, 
                and our <u><a href='\docs\Orchid-Surgical-HIPAA-Security-Policies-and-Tags.pdf' download>HIPAA Security Policies</a></u> for more information.
            </p>
            </FAQContainer>
            <FAQContainer>
            <h3>Got Feedback?</h3>
            <p>
                <b>Found a bug? Got a feature request? We'd love to hear from you!</b><br/>
                Please send us an email at <u><a href="mailto:contact@orchidsurgical.com">contact@orchidsurgical.com</a></u>.
            </p>
            </FAQContainer>
        </Container>
     );
}
 
export default Help;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-top: 80px;
    padding: 20px;
    overflow: visible;
    max-width: 1080px;
`;

const FAQContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* margin: auto; */
    margin-top: 20px;
    padding: 20px;
    overflow: visible;
    text-align: left;
    background: #f5f5f5;
    border-radius: 40px;
`;