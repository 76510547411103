import styled from 'styled-components';
import { getUserAdmin, deleteUserAdmin, updateUserAdmin, hijackUser, inviteUser } from '../services/api/admin_users.js';
import DetailView from './DetailView.js';
import { useParams } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { prefix_enum } from '../enums/prefix.js';
import { specialization_enum } from '../enums/specialization.js';
import { requestPasswordReset } from '../services/api/authentication.js';


const AdminUser = (props) => {

    const { user_id } = useParams();

    let attributes = [
        {
            "key": "id",
            "label": "ID",
            "type": "copy",
            "settable": false,
        },
        {
            "key": "email",
            "label": "Email",
            "type": "string",
            "settable": true,
        },
        {
            "key": "prefix",
            "label": "Title",
            "type": "choice",
            "allow_unset": true,
            "choices": prefix_enum,
            "settable": true,
        },
        {
            "key": "specialization",
            "label": "Specialization",
            "type": "choice",
            "allow_unset": true,
            "choices": specialization_enum,
            "settable": true,
        },
        {
            "key": "first_name",
            "label": "First Name",
            "type": "string",
            "settable": true,
        },
        {
            "key": "middle_name",
            "label": "Middle Name",
            "type": "string",
            "settable": true,
        },
        {
            "key": "last_name",
            "label": "Last Name",
            "type": "string",
            "settable": true,
        },
        {
            "key": "suffix",
            "label": "Suffix",
            "type": "string",
            "settable": true,
        },
        {
            "key": "company",
            "label": "Company",
            "type": "string",
            "settable": true,
        },
        {
            "key": "account_type",
            "label": "Account Type",
            "type": "choice",
            "choices": [
                {
                    "value": "surgeon",
                    "label": "Surgeon",
                },
                {
                    "value": "organization",
                    "label": "Organization",
                },
            ],
            "settable": true,
        },
        {
            "key": "tier",
            "label": "Tier",
            "type": "choice",
            "choices": [
                {
                    "value": "free",
                    "label": "Free",
                },
                {
                    "value": "pro",
                    "label": "Professional",
                },
            ],
            "settable": true,
        },
        {
            "key": "is_confirmed",
            "label": "Confirmed?",
            "type": "boolean",
            "settable": true,
        },
        {
            "key": "is_admin",
            "label": "Admin?",
            "type": "boolean",
            "settable": true,
            "tooltip": {
                "variant": "warning",
                "text": "Admins have access to all features and can manage other users! This should be reserved for Orchid staff only.",
            }
        },
        {
            "key": "is_active",
            "label": "Active?",
            "type": "boolean",
            "settable": true,
        },
        {
            "key": "can_export_without_watermark",
            "label": "Can Export Without Watermark?",
            "type": "boolean",
            "settable": true,
        },

        // Action buttons
        {
            "label": "Simulate",
            "type": "action",
            "onClick": (
                function(user) {
                    hijackUser(user.id).then((response) => {
                        localStorage.setItem('isHijacked', true);
                        props.setUser(response);
                        window.location.replace('/home');
                    })
                }
            ),
            "variant": "warning",
            "tooltip": {
                "variant": "warning",
                "text": "Will notify the user that their account has been accessed.",
            }
        },
        {
            "label": "Invite",
            "type": "action",
            "onClick": (
                function(user) {
                    inviteUser(user.id).then((response) => {
                        alert("Invitation sent!");
                    })
                }
            ),
            "tooltip": {
                "text": "Will send an invitation email to the user. Disabled if the user has already accepted the TOS."
            }
        },
        {
            "label": "Send Password Reset",
            "type": "action",
            "onClick": (
                function(user) {
                    requestPasswordReset(user.email).then((response) => {
                        alert("Password reset email sent!");
                    })
                }
            ),
            "tooltip": {
                "text": "Will send a password reset email to the user."
            }
        },
        {
            "label": "Subscriptions",
            "type": "action",
            "onClick": (
                function(user) {
                    window.open(`https://dashboard.stripe.com/customers/${user.subscriptions[0].stripe_customer_id}`, '_blank');
                }
            ),
            "isDisabled": (user) => {
                return !user.subscriptions || user.subscriptions.length === 0;
            },
            "tooltip": {
                "text": "View the user's subscriptions in Stripe. Disabled if the user has no subscriptions."
            }
        }
    ];

    return (
        <Page>
            <DetailView
                title="Admin: User"
                get_method={getUserAdmin}
                update_method={updateUserAdmin}
                delete_method={deleteUserAdmin}
                attributes={attributes}
                primary_key={user_id} />
        </Page>
    );
}

AdminUser.propTypes = {
    setUser: PropTypes.func.isRequired,
}

export default AdminUser;

const Page = styled.div`
    position: absolute;
    flex-flow: column nowrap;
    top: 70px;
    display: flex;
    height: calc(100vh - 70px);
    width: 100vw;
    align-items: center;
    overflow: auto;
`;
