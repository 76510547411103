import { url_api } from "../../sources";
import { get, patch, del, post} from "./common.js";


export function getPublicKey() {
    return get('/stripe/public_key/');
}


export function getCheckoutSession(checkout_mode, is_international) {
    checkout_mode = checkout_mode || 'platform_only';
    is_international = is_international || false;
    return post('/stripe/checkout/', {checkout_mode: checkout_mode, is_international: is_international});
}


export function getCheckoutSessionEcsClub(checkout_mode, is_international) {
    checkout_mode = checkout_mode || 'platform_only';
    is_international = is_international || false;
    return post('/stripe/checkout/', {checkout_mode: checkout_mode, is_international: is_international, ecs_discount: true});
}


export function getSubscription() {
    return get('/me/subscription/');
}


export function cancelSubscription() {
    return del('/me/subscription/');
}


export function reactivateSubscription() {
    return patch('/me/subscription/');
}


export function getReceipt(session_id) {
    return get('/stripe/receipt/' + session_id + '/');
}
