import React, { useEffect } from "react";
import styled from "styled-components";
import Button from "../components/Button";
import TextInput from "../components/TextInput";
import Header from "../components/Header";
import VideoDemo from "../components/VideoDemo";
import MobileVideoDemo from "../components/MobileVideoDemo";
import Footer from "../components/Footer";
import { GlassContainer } from "../components/GlassContainer";
import { Link, useNavigate } from "react-router-dom";
import Form from "../components/Form";
import useScrollReporter from "../../hooks/useScrollReporter";
import { useMediaQuery } from 'react-responsive';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet";
import mixpanel from "mixpanel-browser";

const LandingPage = () => {

  useScrollReporter('Landing Page')
  const navigate = useNavigate();

  useEffect(() => {
    mixpanel.track_pageview('Landing Page');
  }
  , []);

  return (
    <>
    <Helmet>
      <title>Surgical Video Platform | Orchid Surgical</title>
      <meta name="description" content="The toolkit for surgical video. Simplifying capture, secure storage, and collaboration." />
    </Helmet>
    <MobileStyledLandingPage>
      <HeaderBackground>
        <img src="/images/store/Landing/landing_bkg.svg" alt="Surgical Video Camera" />
      </HeaderBackground>
      <Header />
      <Panel>
        <div className="text-container">
          <h1 style={{fontSize: '64px', lineHeight: '70px'}}>
            <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600"}}>
              One Platform For 
            </span>
            <span style={{color: "#3D85BB", fontFamily: "Poppins", fontWeight: "700", fontSize: '66px'}}>
              <wbr/> Surgical Video
            </span>
          </h1>
          <div style={{textAlign: 'left'}}>
          <p style={{
              textAlign: "left",
              color: '#7E7E7E',
              fontSize: 18,
              fontFamily: 'Quicksand',
              fontWeight: '400',
              textTransform: 'capitalize',
            }}> 
            <b>Orchid Surgical</b> makes the toolkit for surgical video. Simplifying capture, secure storage, and collaboration.
          </p>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center', position: 'relative'}}>
            <Button text='Explore Plans & Pricing &nbsp;&nbsp;&nbsp;' color="#3D85BB" textColor="white" width="300px" onClick={()=>{navigate('/shop')}}/>
            <img src="/images/icons/arrow-narrow-right.svg" alt="more" style={{position: 'absolute', right: '42px', top: '11px'}}/>
          </div>          
          <div style={{fontSize: 18, fontFamily: 'Quicksand', fontWeight: '500'}}>
            <span style={{color: '#BFBFBF'}}>Already On Orchid Cloud? </span>
            <StyledLink to={'/login'}> Sign In</StyledLink>
          </div>
        </div>
        <div className="content-container">
            <MobileVideoDemo videos={[
              {
                "title": "CABG",
                "date": "11/14/2024",
                "duration": "4h 35m",
                "tags": ["Cardio", "tag2", "tag3"],
                "author": "Jeremy Morales",
                "thumbnail": "/images/store/Landing/cabg_thumbnail.png",
                "chapters": 12,
                "vidpath": "/videos/cabg_demo.mp4"
              },
              {
                "title": "Total Hip Arthroplasty",
                "date": "1/6/2025",
                "duration": "47m",
                "tags": ["Ortho", "tag2", "tag3"],
                "author": "Gary White",
                "thumbnail": "/images/store/Landing/hip_thumbnail.png",
                "chapters": 5,
                "vidpath": "/videos/tha_demo.mp4"
              },
              {
                "title": "Free Flap Elevation",
                "date": "9/27/2024",
                "duration": "3h 2m",
                "tags": ["Plastic", "tag2", "tag3"],
                "author": "Sara McIntosh",
                "thumbnail": "/images/store/Landing/diep_thumbnail.png",
                "chapters": 9,
                "vidpath": "/videos/diep_demo.mp4"
              }
            ]}
            />
        </div>
      </Panel>
      <Panel>
        <div className="text-container">
        <h2 style={{
            fontSize: '60px', 
            lineHeight: '70px', 
            color: "#303030", 
            fontFamily: "Lato", 
            fontWeight: "600", 
            textAlign: 'center'
            }}>
            {"Loupes. "}
            <span style={{color: "#3B418B", fontFamily: "Poppins", fontWeight: "700", fontSize: '62px'}}>
              <wbr />{"Camera. "}
            </span>
            <wbr/>{"Action."}
          </h2>
         </div>
        <div className="content-container">
          <BackgroundShapes src="/images/store/Landing/camera_bkg.svg" alt="Surgical Camera"/>
          <img src="/images/headset_front_nobkg.png" alt="Head-mounted Surgical Camera" style={{width: '100%', maxWidth: '400px'}} />
        </div>
        <div className="text-container">
          <p style={{
              textAlign: "justify",
              color: '#7E7E7E',
              fontSize: 18,
              fontFamily: 'Quicksand',
              fontWeight: '400',
              textTransform: 'capitalize',
            }}> 
              Finally, an affordable camera <b>designed for surgeons</b>. 
              Get the perfect view, with brilliant image quality. 
              Comfortable enough that you can <b>record all day</b>, 
              every day.
            </p>
          {/* <Button text="Coming Soon: Orchid Cameras &rarr;" color="#5f67d894" textColor="white" /> */}
        </div>
      </Panel>
      <Panel>
        <div className="text-container">
          <h2 style={{
            fontSize: '60px', 
            lineHeight: '70px',
            color: "#303030", 
            fontFamily: "Lato", 
            fontWeight: "600",
            textAlign: 'center'
            }}>
            {"All Your Cases, "}
            <span style={{color: "#701D15", fontFamily: "Poppins", fontWeight: "700", fontSize: '62px'}}>
              <br/> {"All In One Place"}
            </span>
          </h2>
        </div>
        <div className="content-container">
          <div style={{position: 'relative', height: '300px', width: '100%'}}>
          <BackgroundShapes src="/images/store/Landing/upload_bkg.svg" alt="Store Surgical Video"/>
          <img src="/images/store/Landing/Sample_Case_1.png" alt="HIPAA-compliant Cloud Storage" 
            style={{height: '80px', position: 'absolute', top: 0, left: '50%', transform: 'translate(-50%, -50%)'}}/>
          <img src="/images/store/Landing/Sample_Case_2.png" alt="Surgical Video" 
            style={{height: '83px', position: 'absolute', top: '80px', left: '60%', transform: 'translate(-50%, -50%)'}}/>
          <img src="/images/store/Landing/Sample_Case_3.png" alt="Surgical Case Video" 
            style={{height: '83px', position: 'absolute', top: '160px', left: '40%', transform: 'translate(-50%, -50%)'}}/>
          <img src="/images/store/Landing/Sample_Case_4.png" alt="Orchid Cloud Video" 
            style={{height: '80px', position: 'absolute', top: '240px', left: '50%', transform: 'translate(-50%, -50%)'}}/>
          </div>
        </div>
        <div className="text-container">
          <p style={{
              textAlign: "justify",
              color: '#7E7E7E',
              fontSize: 18,
              fontFamily: 'Quicksand',
              fontWeight: '400',
              textTransform: 'capitalize',
            }}> 
            Orchid’s HIPAA-Compliant cloud offers <b>unlimited storage</b> for your case video. 
            Organize and annotate your library on a platform where <b>data safety 
            and reliability</b> are the top priorities. 
          </p>
        </div>
      </Panel>
      <Panel>
        <div className="text-container">
        <h2 style={{
            fontSize: '60px', 
            lineHeight: '70px', 
            color: "#303030", 
            fontFamily: "Lato", 
            fontWeight: "600", 
            textAlign: 'center'
            }}>
            <span style={{color: "#6A4711", fontFamily: "Poppins", fontWeight: "700", fontSize: '62px'}}>
              <wbr />{"Effortless "}
            </span>
            <wbr/>{"Editing"}
          </h2>
        </div>
        <div className="content-container">
          <BackgroundShapes src="/images/store/Landing/enrich_bkg.svg" alt="Surgical Video Camera"/>
          <div style={{position: 'relative', width: '100%', height: '200px'}}>
          <img src="/images/store/Landing/segment_image.png" alt="Edit videos of Surgery" 
            style={{height: '200px', position: 'absolute', top: 0, left: 'calc(50% - 0px)', transform: 'translate(-50%, 0%)'}}/>
          <img src="/images/store/Landing/segment1.png" alt="Easy Video Editing" 
            style={{height: '40px', position: 'absolute', top: 10, left: 'calc(80%)', transform: 'translate(-50%, 0%)'}}/>
          <img src="/images/store/Landing/segment2.png" alt="Simple Video Editing" 
            style={{height: '40px', position: 'absolute', top: 52, left: 'calc(80%)', transform: 'translate(-50%, 0%)'}}/>
          <img src="/images/store/Landing/segment3.png" alt="Cloud Video Editing" 
            style={{height: '40px', position: 'absolute', top: 94, left: 'calc(80%)', transform: 'translate(-50%, 0%)'}}/>
          {/* <img src="/images/store/Landing/addnew.png" alt="upload" 
            style={{height: '25px', position: 'absolute', top: 150, left: 'calc(80%)', transform: 'translate(-50%, 0%)'}}/> */}
          <img src="/images/store/Landing/edit_panel.png" alt="Orchid Cloud Video Editing"
            style={{height: '70px', position: 'absolute', top: 0, left: 'calc(50% + 0px)', transform: 'translate(-50%, 200%)'}}/>
          </div>
        </div>
        <div className="text-container">
          <p style={{
              textAlign: "justify",
              color: '#7E7E7E',
              fontSize: 18,
              fontFamily: 'Quicksand',
              fontWeight: '400',
              textTransform: 'capitalize',
            }}> 
            Give your case video that <b>podium polish</b>, right on Orchid. Extract <b>teachable moments</b>. Cut Extraneous Frames.
            Or save some time and just segment your case into <b>clickable chapters</b>.
          </p>
        </div>
      </Panel>
      <Panel>
        <div className="text-container">
          <h2 style={{
            fontSize: '60px', 
            lineHeight: '70px',
            color: "#303030", 
            fontFamily: "Lato", 
            fontWeight: "600",
            textAlign: 'center'
            }}>
            {"A "}
            <span style={{color: "#0C3C83", fontFamily: "Poppins", fontWeight: "700", fontSize: '62px'}}>
              <wbr/>{"Network "}
            </span>
            <wbr/>{"Of Peers"}
          </h2>
        </div>
        <div className="content-container">
          <BackgroundShapes src="/images/store/Landing/Share_bkg.svg" alt="Video Collaboration" />
          <div style={{position: 'relative', width: '100%', height: '300px'}}>
          <img src="/images/store/Landing/message_image.png" alt="Share videos of surgery" 
            style={{height: '180px', position: 'absolute', top: 0, left: '55%', transform: 'translate(-50%, 0%)'}} />
          <img src="/images/store/Landing/message2.png" alt="Exchange surgical knowledge with your peers" 
            style={{height: '70px', position: 'absolute', top: '110px', left: '49%', transform: 'translate(-50%, 50%)'}}/>
          <img src="/images/store/Landing/message3.png" alt="Institutional memory for surgical residencies" 
            style={{height: '70px', position: 'absolute', top: '150px', left: 'calc(70% - 80px)', transform: 'translate(-50%, 50%)'}} />
          <img src="/images/store/Landing/message1.png" alt="Digital education for surgical residents" 
            style={{height: '70px', position: 'absolute', top: '190px', left: 'calc(37% + 50px)', transform: 'translate(-50%, 50%)'}} />
          </div>
        </div>
        <div className="text-container">
          <p style={{
              textAlign: "justify",
              color: '#7E7E7E',
              fontSize: 18,
              fontFamily: 'Quicksand',
              fontWeight: '400',
              textTransform: 'capitalize',
            }}> 
            Securely share case video with <b>any surgeon, anywhere</b>. 
            Use Orchid’s <b>public channels</b> to connect with peers in your specialty, 
            or create a <b>private group</b> to collaborate directly with your community.
          </p>
        </div>
      </Panel>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '200px',
        }}
      >
        <h2 style={{
          textAlign: 'center', 
          fontSize: '54px', 
          lineHeight: '70px', 
          fontFamily: "Lato",
          fontWeight: "700",
          }}>
            Our Partners
        </h2>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '30px', marginTop: '20px'}}>
          <img src="/images/store/Landing/hei.png" alt="AWS Health Equity Initiave Partner" style={{height: '40px'}}/>
          <img src="/images/store/Landing/inception.png" alt="NVIDIA Inception Program Partner" style={{height: '70px'}}/>
        </div>
      </div>
      {/* <PricingPanel>
        <div className="background-left">
          <img src="/images/store/Landing/big_triangles_left.svg" alt="background shapes" />
        </div>
        <div className="background-right">
          <img src="/images/store/Landing/big_triangles_right.svg" alt="background shapes" />
        </div>
        <div className="call-to-action">
          <h2 style={{textAlign: 'center', fontSize: '60px', lineHeight: '70px'}}>
            <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600"}}>
              Ready to Get Started?
            </span>
          </h2>
          <Form
            placeholder="Your Email" 
            text="Book A Demo" 
            buttonColor="#3D85BB"
            buttonTextColor="#ffffff"
            width="90%"
            maxWidth="400px"
            formName="LIST"
            formValue="Waitlist"
          />
          <div style={{fontSize: 18, fontFamily: 'Quicksand', fontWeight: '500'}}>
            <span style={{color: '#BFBFBF'}}>Already On Orchid Cloud? </span>
            <StyledLink to={'/login/'}> Sign In</StyledLink>
          </div>
        </div>
    </PricingPanel> */}
      <Footer/>
    </MobileStyledLandingPage>
    <StyledLandingPage>
      <HeaderBackground>
        <img src="/images/store/Landing/landing_bkg.svg" alt="Surgical Video Camera" />
      </HeaderBackground>
      <Header />
      <Panel>
        <div className="text-container">
          <h1 style={{fontSize: '68px', lineHeight: '70px'}}>
            <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600"}}>
              One Platform For 
            </span>
            <span style={{color: "#3D85BB", fontFamily: "Poppins", fontWeight: "700", fontSize: '70px'}}>
              <wbr/> Surgical Video
            </span>
          </h1>
          <div style={{textAlign: 'left'}}>
          <p style={{
              textAlign: "left",
              color: '#7E7E7E',
              fontSize: 18,
              fontFamily: 'Quicksand',
              fontWeight: '400',
              textTransform: 'capitalize',
            }}> 
            <b>Orchid Surgical makes the toolkit for surgical video</b>. 
            Simplifying capture, secure storage, and collaboration.
          </p>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center', position: 'relative'}}>
            <Button text='Explore Plans & Pricing &nbsp;&nbsp;&nbsp;' color="#3D85BB" textColor="white" width="300px" onClick={()=>{navigate('/shop')}}/>
            <img src="/images/icons/arrow-narrow-right.svg" alt="more" style={{position: 'absolute', right: '42px', top: '11px'}}/>
          </div>
          <div style={{fontSize: 18, fontFamily: 'Quicksand', fontWeight: '500'}}>
            <span style={{color: '#BFBFBF'}}>Already On Orchid Cloud? </span>
            <StyledLink to={'/login'}> Sign In</StyledLink>
          </div>
        </div>
        <div className="content-container">
          <GlassContainer style={{zIndex: 10, height: '460px'}}>
            <VideoDemo videos={[
              {
                "title": "CABG",
                "date": "11/14/2024",
                "duration": "4h 35m",
                "tags": ["Cardio", "tag2", "tag3"],
                "author": "Jeremy Morales",
                "thumbnail": "/images/store/Landing/cabg_thumbnail.png",
                "chapters": 12,
                "vidpath": "/videos/cabg_demo.mp4"
              },
              {
                "title": "Total Hip Arthroplasty",
                "date": "1/6/2025",
                "duration": "47m",
                "tags": ["Ortho", "tag2", "tag3"],
                "author": "Gary White",
                "thumbnail": "/images/store/Landing/hip_thumbnail.png",
                "chapters": 5,
                "vidpath": "/videos/tha_demo.mp4"
              },
              {
                "title": "Free Flap Elevation",
                "date": "9/27/2024",
                "duration": "3h 2m",
                "tags": ["Plastic", "tag2", "tag3"],
                "author": "Sara McIntosh",
                "thumbnail": "/images/store/Landing/diep_thumbnail.png",
                "chapters": 9,
                "vidpath": "/videos/diep_demo.mp4"
              }
            ]}
            />
          </GlassContainer>
        </div>
      </Panel>
      <Panel>
        <div className="content-container"
          style={{position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', width: '100%', height: '400px', gap: '20px'}}>
          <BackgroundShapes style={{width:'450px'}} src="/images/store/Landing/camera_bkg.svg" alt="Surgical Video Camera"/>
          <img src="/images/headset_front_nobkg.png" alt="The Orchid head-mounted surgical camera" height="340px" style={{position: 'relative'}}/>
          {/* pageWidth && <img src="/images/camera_vertical_nobkg.png" alt="Head-mounted Surgical Camera" height='320px' style={{position: 'relative'}} /> */}
        </div>
        <div className="text-container">
          <h2 style={{
              fontSize: '68px', 
              lineHeight: '70px', 
              color: "#303030", 
              fontFamily: "Lato", 
              fontWeight: "600", 
              textAlign: 'left'
              }}>
              {"Loupes. "}
              <span style={{color: "#3B418B", fontFamily: "Poppins", fontWeight: "700", fontSize: '70px'}}>
                <wbr />{"Camera. "}
              </span>
              <wbr />{"Action."}
          </h2>
          <p style={{
              textAlign: "left",
              color: '#7E7E7E',
              fontSize: 18,
              fontFamily: 'Quicksand',
              fontWeight: '400',
              textTransform: 'capitalize',
            }}> 
              Finally, an affordable camera <b>designed for surgeons</b>. 
              Get the perfect view, with brilliant image quality. 
              Comfortable enough that you can <b>record all day</b>, 
              every day.             
          </p>
          {/* <Button text="Coming Soon: Orchid Cameras &rarr;" color="#5f67d894" textColor="white" /> */}
        </div>
      </Panel>
      <Panel>
        <div className="text-container">
        <h2 style={{
            fontSize: '68px', 
            lineHeight: '70px', 
            color: "#303030", 
            fontFamily: "Lato", 
            fontWeight: "600", 
            textAlign: 'left'
            }}>
            {"All Your Cases, "}
            <span style={{color: "#701D15", fontFamily: "Poppins", fontWeight: "700", fontSize: '70px'}}>
              <wbr/> {"All In One Place"}
            </span>
          </h2>
          <p style={{
              textAlign: "left",
              color: '#7E7E7E',
              fontSize: 18,
              fontFamily: 'Quicksand',
              fontWeight: '400',
              textTransform: 'capitalize',
            }}> 
            Orchid’s HIPAA-Compliant cloud offers <b>unlimited storage</b> for your case video. 
            Organize and annotate your library on a platform where <b>data safety 
            and reliability</b> are the top priorities.
          </p>
        </div>
        <div className="content-container">
          <div style={{position: 'relative', width: '100%', height: '420px'}}>
          <BackgroundShapes src="/images/store/Landing/upload_bkg.svg" alt="HIPAA-compliant Cloud Storage"/>
          <img src="/images/store/Landing/Sample_Case_1.png" alt="HIPAA-compliant Cloud Storage" 
            style={{height: '110px', position: 'absolute', top: 0, left: '50%', transform: 'translate(-50%, 0%)'}}/>
          <img src="/images/store/Landing/Sample_Case_2.png" alt="Surgical Video on Orchid Cloud" 
            style={{height: '123px', position: 'absolute', top: 110, left: '60%', transform: 'translate(-50%, 0%)'}}/>
          <img src="/images/store/Landing/Sample_Case_3.png" alt="Surgical Video on Orchid Cloud" 
            style={{height: '123px', position: 'absolute', top: 225, left: '40%', transform: 'translate(-50%, 0%)'}}/>
          <img src="/images/store/Landing/Sample_Case_4.png" alt="Surgical Video on Orchid Cloud" 
            style={{height: '110px', position: 'absolute', top: 340, left: '50%', transform: 'translate(-50%, 0%)'}}/>
          </div>
        </div>
      </Panel>
      <Panel>
        <div className="content-container">
          <BackgroundShapes src="/images/store/Landing/enrich_bkg.svg" alt="Edit Surgical Video on Orchid Cloud"/>
          <div style={{position: 'relative', width: '100%', minWidth: '450px', height: '300px'}}>
          <img src="/images/store/Landing/segment_image.png" alt="Edit Surgical Video on Orchid Cloud" 
            style={{height: '300px', width: 'auto', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>
          <img src="/images/store/Landing/segment1.png" alt="Edit Surgical Video" 
            style={{height: '60px', position: 'absolute', top: 10, left: 'calc(300px)'}}/>
          <img src="/images/store/Landing/segment2.png" alt="Cloud Editing Surgical Video" 
            style={{height: '60px', position: 'absolute', top: 84, left: 'calc(260px + 12%)'}}/>
          <img src="/images/store/Landing/segment3.png" alt="Simple Editing Surgical Video" 
            style={{height: '60px', position: 'absolute', top: 158, left: 'calc(220px + 24%)'}}/>
          {/* <img src="/images/store/Landing/addnew.png" alt="upload" 
            style={{height: '45px', position: 'absolute', top: 242, left: 'calc(240px + 24%)'}}/> */}
          <img src="/images/store/Landing/edit_panel.png" alt="Easy Editing Surgical Video"
            style={{height: '100px', position: 'absolute', top: 0, left: 'calc(50% + 0px)', transform: 'translate(-50%, 220%)'}}/>
          </div>
        </div>
        <div className="text-container">
        <h2 style={{
            fontSize: '68px', 
            lineHeight: '70px', 
            color: "#303030", 
            fontFamily: "Lato", 
            fontWeight: "600", 
            textAlign: 'left'
            }}>
            <span style={{color: "#6A4711", fontFamily: "Poppins", fontWeight: "700", fontSize: '70px'}}>
              {"Effortless "}
            </span>
            <wbr/>{"Video Editing"}
          </h2>
          <p style={{
              textAlign: "left",
              color: '#7E7E7E',
              fontSize: 18,
              fontFamily: 'Quicksand',
              fontWeight: '400',
              textTransform: 'capitalize',
            }}> 
            Give your case video that <b>podium polish</b>, right on Orchid. Extract Teachable moments. Cut Extraneous Frames.
            Or save some time and just segment your case into <b>clickable chapters</b>. 
          </p>
        </div>
      </Panel>
      <Panel>
        <div className="text-container">
        <h2 style={{
            fontSize: '68px', 
            lineHeight: '70px', 
            color: "#303030", 
            fontFamily: "Lato", 
            fontWeight: "600", 
            textAlign: 'left'
            }}>
            {"A "}
            <span style={{color: "#0C3C83", fontFamily: "Poppins", fontWeight: "700", fontSize: '70px'}}>
              <wbr/>{"Network "}
            </span>
            <wbr/>{"Of Peers"}
          </h2>
          <p style={{
              textAlign: "left",
              color: '#7E7E7E',
              fontSize: 18,
              fontFamily: 'Quicksand',
              fontWeight: '400',
              textTransform: 'capitalize',
            }}> 
            Securely share case video with <b>any surgeon, anywhere</b>. 
            Use Orchid’s <b>public channels</b> to connect with peers in your specialty, 
            or create a <b>private group</b> to collaborate directly with your community.
          </p>
        </div>
        <div className="content-container">
          <BackgroundShapes src="/images/store/Landing/Share_bkg.svg" alt="Surgical Video Camera" />
          <div style={{position: 'relative', width: '100%', minWidth: '450px', height: '400px'}}>
          <img src="/images/store/Landing/message_image.png" alt="Share videos of surgery" 
            style={{height: '250px', position: 'absolute', top: 0, left: '55%', transform: 'translate(-50%, 0%)'}} />
          <img src="/images/store/Landing/message2.png" alt="Exchange surgical knowledge with your peers" 
            style={{height: '100px', position: 'absolute', top: '150px', left: '50%', transform: 'translate(-50%, 50%)'}}/>
          <img src="/images/store/Landing/message3.png" alt="Institutional memory for surgical residencies" 
            style={{height: '100px', position: 'absolute', top: '205px', right: '0', transform: 'translate(10%, 50%)'}} />
          <img src="/images/store/Landing/message1.png" alt="Digital education for surgical residents" 
            style={{height: '100px', position: 'absolute', top: '255px', left: '0', transform: 'translate(-10%, 50%)'}} />
          </div>
        </div>
      </Panel>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '200px',
        }}
      >
        <h2 style={{
          textAlign: 'center', 
          fontSize: '60px', 
          lineHeight: '70px', 
          fontFamily: "Lato",
          fontWeight: "700",
          }}>
            Our Partners
        </h2>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '30px', marginTop: '20px'}}>
          <img src="/images/store/Landing/hei.png" alt="AWS Health Equity Initiave Partner" style={{height: '40px'}}/>
          <img src="/images/store/Landing/inception.png" alt="NVIDIA Inception Program Partner" style={{height: '70px'}}/>
        </div>
      </div>
      {/* <PricingPanel>
        <div className="background-left">
          <img src="/images/store/Landing/big_triangles_left.svg" alt="Surgical Video Camera" />
        </div>
        <div className="background-right">
          <img src="/images/store/Landing/big_triangles_right.svg" alt="Surgical Video Camera" />
        </div>
        <div className="call-to-action">
          <h2 style={{textAlign: 'center', fontSize: '68px', lineHeight: '70px', maxWidth:"50vw"}}>
            <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600"}}>
              Ready to Get Started?
            </span>
          </h2>
          <Form 
            placeholder="Your Email"
            text="Book A Demo"
            buttonColor="#3D85BB"
            buttonTextColor="#ffffff"
            formName="LIST"
            formValue="WaitList"
            width="400px"
          />
          <div style={{fontSize: 18, fontFamily: 'Quicksand', fontWeight: '500'}}>
            <span style={{color: '#BFBFBF'}}>Already On Orchid Cloud? </span>
            <StyledLink to={'/login/'}> Sign In</StyledLink>
          </div>
        </div>
    </PricingPanel> */}
      <Footer/>
    </StyledLandingPage>
    </>
  );
};

export default LandingPage;

export const StyledLandingPage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;

  @media only screen and (max-width: 900px) {
    display:none;
  }

`;

export const MobileStyledLandingPage = styled.div`
  @media only screen and (min-width: 900px) {
    display: none;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
`;

export const HeaderBackground = styled.div`
  position: absolute;
  width: 720px;
  height: auto;
  z-index: -10;
  right: 0;
  top: 0;

  img {
    height: 100%;
    width: 100%;
  }

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const BackgroundShapes = styled.img`
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  width: 135%;
  height: 135%;
  transform: translate(-50%, -50%);
  object-fit: contain;
`;

export const Panel = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-around; */
  width: 100%;
  height: 100%;
  padding: 0 100px;
  gap: 100px;
  margin-top: 20px;
  margin-bottom: 100px;
  max-width: 1600px;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    padding: 0 10px;
    gap: 40px;
    margin-bottom: 40px;
  }

  /* border: 1px solid black; */
  .text-container {
    flex: 1 1 0;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    white-space: wrap;
    word-wrap: break-word;
    text-align: left;
    width: 100%;
    height: 100%;
    gap: 20px;
    max-width: 800px;
    flex-wrap: nowrap;
    /* border: 1px solid red; */
  }

  .content-container {
    position: relative;
    flex: 1 1 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 0;
    /* border: 1px solid blue; */
  }

`;

const PricingPanel = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 500px;
  gap: 100px;
  background-color: #F5F5F5;

  .background-left{
    position: absolute;
    left: -50px;
    top: 0;
    img {
      height: 100%;
      width: 100%;
    }

    @media only screen and (max-width: 900px) {
      opacity: .2;
    }
  }

  .background-right{
    position: absolute;
    right: -50px;
    bottom: 0;
    img {
      height: 100%;
      width: 100%;
    }

    @media only screen and (max-width: 900px) {
      opacity: .2;
    }
  }

  .call-to-action {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 900px) {
      text-align: center;
    }
  }

`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #444A9F !important;
  font-size: 18px;
  font-weight: 600;

`;

